import historyTrailActionModule from './historyTrail-action';

const initializeState = () => ({
  
  historytrail: {  },
});

export default ({ $http }) => ({
  state: {
    ...initializeState(),
  },
  mutations: {
    setHistoryTrailInStore(state, payload) {
      state.historytrail = payload || { };
      // if(state.skillcategory){
      //   payload.masters.map(s => (s.key = 'skillCategory', s));
      //   payload.primarySkills.map(s => (s.key = 'primarySkill', s));
      //   payload.subSkills.map(s => (s.key = 'subSkill', s));
      // }
    },
  },
  actions: historyTrailActionModule({ $http }),
  getters: {
    historyTrail: (state) => state.historytrail,
  },
});

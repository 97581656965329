// eslint-disable-next-line operator-linebreak
const baseUrl1 = window.location.origin.includes('admin.genome.genpact.com')
  ? 'https://api.genpact.com/prod/genome/data-hose'
  : 'https://api.genpact.com/uat/genome/data-hose';
const baseUrl = window.location.origin.includes('admin.genome.genpact.com') ? 'https://api.genpact.com/prod/genome/my-profile' : 'https://genome-dev.genpact.com/api/my-profile';
const checkAccess = (context, page, actionName) => {
  const accessJson = JSON.parse(localStorage.getItem('rbac_user'));
  const findObj = accessJson.pages.find((obj) => obj.code === page) || {};
  const userHasAccess = 'permission' in findObj && findObj.permission.some((objx) => objx === actionName);
  // alert(JSON.stringify(userHasAccess))
  if (!userHasAccess) {
    context.dispatch('addToastNotifications', {
      text: "You don't have permission to perform this action!",
      type: 'error',
      timer: 3000,
    });
    return true;
  }
  return false;
};

export default ({ $http }) => ({
  // Get all JobProfileGroup
  async getJobGroup(context, data) {
    if (checkAccess(context, 11, 'read')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/jobarchitecture/mdmlist`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    await $http
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        context.commit('setJobGroupInStore', response?.data || {});
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  // Get Job Family
  async getJobFamily(context, data) {
    if (checkAccess(context, 11, 'read')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/jobarchitecture/mdmlist`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    await $http
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        context.commit('setJobFamilyInStore', response?.data || {});
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  // Get Job Title
  async getJobTitle(context, data) {
    if (checkAccess(context, 11, 'read')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/jobarchitecture/mdmlist`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    await $http
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        context.commit('setJobTitleInStore', response?.data || {});
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  // Get Job Profile
  async getJobProfile(context, data) {
    if (checkAccess(context, 11, 'read')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/jobarchitecture/mdmlist`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    await $http
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        context.commit('setJobProfileInStore', response?.data || {});
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  // Get Role List
  async getRoleList(context) {
    if (checkAccess(context, 11, 'read')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/job-profile-role-mapping/mdmrolelist`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
    };
    await $http
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        context.commit('setRoleListInStore', response?.data || {});
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  // Get Role List
  async getJobProfileList(context) {
    if (checkAccess(context, 5, 'read')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/job-profile-role-mapping/mdmjobprofilelist`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
    };
    await $http
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        context.commit('setJobProfileListInStore', response?.data || {});
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  // Add/Update JobProfileGroup
  async updateJobGroup(context, data) {
    if (checkAccess(context, 11, 'update')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/jobarchitecture/update`,
      headers: {
        'content-type': 'application/json',
      },
      data,
    };
    const jobData = await $http
      .request(config)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return jobData;
  },

  // Get Core Skill
  async getCoreSkillList(context, data) {
    if (checkAccess(context, 11, 'read')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/coreskill/list`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data
    };
    await $http
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        context.commit('setCoreSkillListInStore', response?.data || {});
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  // Add/Update Core Skill
  async updateCoreSkill(context, data) {
    if (checkAccess(context, 11, 'update')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/coreskill/update`,
      headers: {
        'content-type': 'application/json',
      },
      data,
    };
    const jobData = await $http
      .request(config)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return jobData;
  },
  // Get Skill Category
  async getCategoryList(context, data) {
    if (checkAccess(context, 11, 'read')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl1}/getMdmSkills`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    await $http
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        context.commit('setCategoryListInStore', response?.data || {});
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  // Get Function List
  async getFunctionList(context, data) {
    if (checkAccess(context, 11, 'read')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl1}/getMdmSkills`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    await $http
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        context.commit('setFunctionListInStore', response?.data || {});
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  // Update skill status
  async activateSkillStatus(context, data) {
    if (checkAccess(context, 5, 'update')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/skill/updatestatus`,
      headers: {
        'content-type': 'application/json',
      },
      data,
    };
    const skillStatus = await $http
      .request(config)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return skillStatus;
  },
  async updateJASkillStatus(context, data) {
    if (checkAccess(context, 11, 'update')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/jobarchitecture/updatestatus`,
      headers: {
        'content-type': 'application/json',
      },
      data,
    };
    const skillStatus = await $http
      .request(config)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return skillStatus;
  },
  async updateCoreSkillStatus(context, data) {
    if (checkAccess(context, 11, 'update')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/coreskill/updatestatus`,
      headers: {
        'content-type': 'application/json',
      },
      data,
    };
    const skillStatus = await $http
      .request(config)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return skillStatus;
  },
});

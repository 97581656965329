import Vue from 'vue';
import VueRouter from 'vue-router';
// import { Auth } from 'aws-amplify';

Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
    children: [
      {
        path: '',
        name: 'Dashboard.Download',
        component: () => import(/* webpackChunkName: "Dashboard.Download" */ '@/views/Download.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/learner-score-reports',
        name: 'Dashboard.LSReports',
        component: () => import(/* webpackChunkName: "Dashboard.LSReports" */ '@/views/LSReports.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/upload',
        name: 'Dashboard.Upload',
        component: () => import(/* webpackChunkName: "Dashboard.Upload" */ '@/views/Upload.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/upload-solidify',
        name: 'Dashboard.Solidify',
        component: () => import(/* webpackChunkName: "Dashboard.Solidify" */ '@/views/UploadSolidify.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/skills-master',
        name: 'Dashboard.Skills',
        component: () => import(/* webpackChunkName: "Dashboard.Skills" */ '@/views/Skills.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/skills-master/skill-policies/:mhId',
        name: 'Dashboard.Skills.SkillPolicies',
        component: () => import(/* webpackChunkName: "Dashboard.Skills.SkillPolicies" */ '@/views/SkillPolicies.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/skills-master/subskill-policies/:mhId',
        name: 'Dashboard.Skills.SubSkillPolicies',
        component: () => import(/* webpackChunkName: "Dashboard.Skills.SubSkillPolicies" */ '@/views/SubSkillPolicies.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/policies',
        name: 'Dashboard.Policies',
        component: () => import(/* webpackChunkName: "Dashboard.Policies" */ '@/views/Policies.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/policies/policies-skill/:mhId',
        name: 'Dashboard.PoliciesSkill',
        component: () => import(/* webpackChunkName: "Dashboard.PoliciesSkill" */ '@/views/PoliciesSkill.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/release-management',
        name: 'Dashboard.ReleaseManagement',
        component: () => import(/* webpackChunkName: "Dashboard.ReleaseManagemen" */ '@/views/ReleaseManagement.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/event-list',
        name: 'Dashboard.EventList',
        component: () => import(/* webpackChunkName: "Dashboard.EventList" */ '@/views/EventList.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/:eventType/:eventId',
        name: 'Dashboard.EventData',
        component: () => import(/* webpackChunkName: "Dashboard.EventData" */ '@/views/EventData.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/archetype',
        name: 'Dashboard.Archetype',
        component: () => import(/* webpackChunkName: "Dashboard.Archetype" */ '@/views/Archetype.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/readme-grb',
        name: 'Dashboard.grb.ReadMeGRB',
        component: () => import(/* webpackChunkName: "Dashboard.grb.ReadMeGRB" */ '@/views/ReadMeGRB.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/skill-category',
        name: 'Dashboard.grb.SkillCategory',
        component: () => import(/* webpackChunkName: "Dashboard.grb.SkillCategory" */ '@/views/SkillCategory.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/skill-capability',
        name: 'Dashboard.grb.SkillCapability',
        component: () => import(/* webpackChunkName: "Dashboard.grb.SkillCapability" */ '@/views/SkillCapability.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/sub-skill-capability',
        name: 'Dashboard.grb.SubSkillCapability',
        component: () => import(/* webpackChunkName: "Dashboard.grb.SubSkillCapability" */ '@/views/SubSkillCapability.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/mg-certificate',
        name: 'Dashboard.grb.MGCertificate',
        component: () => import(/* webpackChunkName: "Dashboard.grb.MGCertificate" */ '@/views/MGCertificate.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/critical-skill',
        name: 'Dashboard.grb.CriticalSkills',
        component: () => import(/* webpackChunkName: "Dashboard.grb.CriticalSkills" */ '@/views/CriticalSkills.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/skill-channel',
        name: 'Dashboard.grb.SkillChannel',
        component: () => import(/* webpackChunkName: "Dashboard.grb.SkillChannel" */ '@/views/SkillChannel.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/sub-skill-channel',
        name: 'Dashboard.grb.SubSkillChannel',
        component: () => import(/* webpackChunkName: "Dashboard.grb.SubSkillChannel" */ '@/views/SubSkillChannel.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/history-trail',
        name: 'Dashboard.grb.HistoryTrail',
        component: () => import(/* webpackChunkName: "Dashboard.grb.HistoryTrail" */ '@/views/HistoryTrail.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/jobprofile-management',
        name: 'Dashboard.JobFamilyGroup',
        component: () => import(/* webpackChunkName: "Dashboard.JobFamilyGroup" */ '@/views/JobFamilyGroup.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/linkedin-content-search',
        name: 'Dashboard.LinkedIn.ContentSearch',
        component: () => import(/* webpackChunkName: "Dashboard.LinkedIn.ContentSearch" */ '@/views/LinkedInContentSearch.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/linkedin-learning-activities',
        name: 'Dashboard.LinkedIn.LearningActivities',
        component: () => import(/* webpackChunkName: "Dashboard.LinkedIn.LearningActivities" */ '@/views/LinkedInLearningActivities.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/linkedin-job-execution-history',
        name: 'Dashboard.LinkedIn.JobExecutionHistory',
        component: () => import(/* webpackChunkName: "Dashboard.LinkedIn.JobExecutionHistory" */ '@/views/LinkedInJobExecutionHistory.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/persona-management',
        name: 'Dashboard.PersonaManagement',
        component: () => import(/* webpackChunkName: "Dashboard.PersonaManagement" */ '@/views/PersonaManagement.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/academy',
        name: 'Dashboard.AcademyManagement',
        component: () => import(/* webpackChunkName: "dashboard.RuleEngine" */ '@/views/AcademyManagement.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/academy/modules/:acadId',
        name: 'Dashboard.Modules',
        component: () => import(/* webpackChunkName: "dashboard.RuleEngine" */ '@/views/Modules.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/academy/learners/:acadId',
        name: 'Dashboard.Learners',
        component: () => import(/* webpackChunkName: "dashboard.RuleEngine" */ '@/views/Learners.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/academy/enrollment-request/:acadId',
        name: 'Dashboard.EnrollmentRequest',
        component: () => import(/* webpackChunkName: "dashboard.RuleEngine" */ '@/views/EnrollmentRequest.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/academy/enrollment-status/:acadId',
        name: 'Dashboard.EnrollmentStatus',
        component: () => import(/* webpackChunkName: "dashboard.RuleEngine" */ '@/views/EnrollmentStatus.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/academy/function&role-mapping/:acadId',
        name: 'Dashboard.Function&Role',
        component: () => import(/* webpackChunkName: "dashboard.RuleEngine" */ '@/views/Function&Role.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/hct-operation',
        name: 'Dashboard.HctOperation',
        component: () => import(/* webpackChunkName: "Dashboard.EventList" */ '@/views/HctOperation.vue'),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/login',
    name: 'Auth.Login',
    component: () => import(/* webpackChunkName: "auth.login" */ '@/views/Login.vue'),
  },
  {
    path: '/signout',
    name: 'Auth.Logout',
    component: () => import(/* webpackChunkName: "auth.logout" */ '@/views/Logout.vue'),
  },
  {
    path: '*',
    redirect: '/',
  },
];

const createRouter = ({ store }) => {
  const router = new VueRouter({
    routes,
    mode: 'history',
    fallback: false,
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
      if (from.meta.saveScrollPos) {
        store.dispatch('setViewScrollPos', {
          name: from.name,
          coordinates: {
            x: window.scrollX,
            y: window.scrollY,
          },
        });
      }
      if (savedPosition) {
        return savedPosition;
      }
      return { x: 0, y: 0 };
    },
  });

  // router.beforeResolve((to, from, next) => {
  //   if (to.matched.some((record) => record.meta.requiresAuth)) {
  //     Auth.currentAuthenticatedUser()
  //       .then(async () => {
  //         if (!store.getters.isLoggedIn) await store.dispatch('getAmplifySession');
  //         next();
  //       })
  //       .catch((err) => {
  //         console.warn(err);
  //         next({
  //           path: '/login',
  //         });
  //       });
  //   }
  //   next();
  // });

  router.afterEach(() => {
    // store.dispatch('clearViewScrollPos', to.name);
    // @ts-ignore
    if (store.state.router.next) store.dispatch('clearNextRoute');
  });

  return router;
};

export default createRouter;

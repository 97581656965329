<!-- eslint-disable max-len -->
<template>
  <section class="">
    <div @click="toggleValChange" class="flex items-center rounded-full h-4 w-7 relative text-white text-xs cursor-pointer" :class="toggleVal ? 'bg-genome-aqua-blue justify-start' : 'bg-gray-200 justify-end'">
      <span class="absolute h-3 bg-white w-3 rounded-full transition-all duration-200" :class="toggleVal ? 'right-0.5' : 'left-0.5'" />
    </div>
    <modal-comp :ref="`toggle-button-${modalId}-modal`" :open-modal="false" :show-close-btn="true" :name="`toggle-button-${modalId}-modal`" :custom-class="'sm:w-2/3 md:w-1/2 lg:w-5/12 h-auto modal-content-cont-1 sm:rounded-lg p-4'">
      <section class="m-8">
        <h1 class="mb-2 text-xl text-gray-800">
          <b>{{ skillName }}</b>
        </h1>
        <p class="mb-2 text-xl text-gray-800">
          {{ modalProps.title }}
        </p>
        <p v-if="toggleVal" class="my-2 mb-4 text-gray-800" v-html="modalProps.trueBody" />
        <p v-else class="my-2 mb-4 text-gray-800" v-html="modalProps.falseBody" />
        <div class="relative py-6 w-full">
          <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-3">
            Reason for change<span class="text-red-500">*</span>
          </p>
          <input v-model="reasonForChange" class="focus:outline-none px-4 py-2 text-sm leading-tight text-gray-700 bg-gray-50 border rounded w-full" type="text" placeholder="Enter reason for this change...">
        </div>
        <div class="flex justify-end py-2">
          <button @click="onCancel" class="flex flex-wrap justify-center w-auto mx-2 px-4 py-1 bg-white font-normal text-sm text-gray-500 hover:text-gray-600 border border-gray-200 hover:border-gray-300 rounded-md shadow-button">
            <p>{{ modalProps.cancelBtnLabel }}</p>
          </button>
          <button :disabled="!reasonForChange? !reasonForChange: false " @click="onSave" class="button flex flex-wrap justify-center w-auto mx-2 px-4 py-1 bg-blue-900 font-normal text-sm text-white border border-blue-900 rounded-md shadow-button">
            <p>{{ modalProps.confirmBtnLabel }}</p>
          </button>
        </div>
      </section>
    </modal-comp>
  </section>
</template>

<script>
import ModalComp from '@/components/ModalComp.vue';

export default {
  name: 'ToggleButton',
  components: { ModalComp },
  props: {
    toggleVal: {
      type: Boolean,
      required: true,
    },
    disableToggle: {
      type: Boolean,
      required: false,
    },
    confirmationModal: {
      type: Boolean,
      required: false,
    },
    skillName: {
      type: String,
      default: ''
    },
    modalProps: {
      type: Object,
      default: () => {},
      required: false,
    },
    modalId: {
      type: Number,
      default: () => {},
      required: 0,
    },
  },
  data() {
    return {
      isOpen: false,
      reasonForChange: null,
    };
  },
  watch: {},

  mounted() {},
  methods: {
    toggleValChange() {
      if(!this.disableToggle){
      if (this.confirmationModal) this.$refs[`toggle-button-${this.modalId}-modal`].$data.isOpen = true;
      else this.$emit('toggleValReturn', !this.toggleVal);
      }
    },
    onCancel() {
      this.$refs[`toggle-button-${this.modalId}-modal`].$data.isOpen = false;
      this.$emit('confirmationReturn', false);
    },
    onSave() {
      // const reasonChange = {...this.reasonForChange};
      this.$refs[`toggle-button-${this.modalId}-modal`].$data.isOpen = false;
      this.$emit('confirmationReturn', true);
      this.$emit('toggleValReturn', !this.toggleVal, this.reasonForChange);
    },
  },
};
</script>

import dclModule from './dcl-actions';

const initializeState = () => ({
  allFolders: {},
  allFiles: {},
});

export default ({ $http }) => ({
  state: {
    ...initializeState(),
  },
  mutations: {
    setAllFoldersInStore(state, payload) {
      state.allFolders = payload?.response;
    },
    setAllFilesInStore(state, payload) {
      state.allFiles = payload?.response;
    },
  },
  actions: dclModule({ $http }),
  getters: {
    allFolders: (state) => state.allFolders,
    allFiles: (state) => state.allFiles,
  },
});

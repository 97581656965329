import skillCatogoryActionModule from './subSkillCapability-action';

const initializeState = () => ({
  
  subskillcapability: {  },
});

export default ({ $http }) => ({
  state: {
    ...initializeState(),
  },
  mutations: {
    setSubSkillCapabilityInStore(state, payload) {
      state.subskillcapability = payload || { };
    },
  },
  actions: skillCatogoryActionModule({ $http }),
  getters: {
    subSkillCapability: (state) => state.subskillcapability,
  },
});

import accountsActionModule from './accounts-actions';

const initializeState = () => ({
  accessToken: null,
  user: {},
  allUsers: [],
  preferences: {},
});

export default ({ $http }) => ({
  state: {
    ...initializeState(),
  },
  mutations: {
    setLoggedInState(state, payload) {
      state.accessToken = payload.accessToken ? payload.accessToken : null;
      state.user = payload.user ? { ...payload.user } : {};
    },
    setLoggedOutState(state) {
      Object.assign(state, initializeState());
    },
    setProfilePreferencesInState(state, payload) {
      state.preferences = Object.keys(payload).length ? payload : {};
    },
    setUserInState(state, payload) {
      state.user = Object.keys(payload).length ? payload : {};
    },
    setAllUsersInState(state, payload) {
      state.allUsers = payload?.records?.length ? payload : [];
    },
    updateSessionFromLocalDbInStore(state, payload) {
      state.accessToken = payload.accessToken ? payload.accessToken : null;
      state.user = payload.user && Object.keys(payload.user).length ? { ...payload.user } : {};
    },
  },
  actions: accountsActionModule({ $http }),
  getters: {
    isLoggedIn: (state) => !!state.accessToken,
    allUsers: (state) => state.allUsers,
    user: (state) => {
      const user = { ...state.user };
      user.fullName = `${user.firstName ? user.firstName : ''}${user.lastName ? ` ${user.lastName}` : ''}`;
      return user;
    },
  },
});

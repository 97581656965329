// eslint-disable-next-line object-curly-newline
import { getPoliciesQuery,getPolicyQueryName, createPolicyQuery, updatePolicyQuery, updatePolicyStatusQuery } from '../queries';

// eslint-disable-next-line operator-linebreak
const ENVURL = window.location.origin.includes('admin.genome.genpact.com') ? 'https://api.genpact.com/prod/genome/my-profile/graphql' : 'https://genome-dev.genpact.com/api/my-profile/graphql';

const checkAccess = (context, page, actionName) => {
  const accessJson = JSON.parse(localStorage.getItem('rbac_user'));
  const findObj = accessJson.pages.find((obj) => obj.code === page) || {};
  const userHasAccess = 'permission' in findObj && findObj.permission.some((objx) => objx === actionName);
  if (!userHasAccess) {
    context.dispatch('addToastNotifications', {
      text: "You don't have permission to perform this action!",
      type: 'error',
      timer: 3000,
    });
    return true;
  }
  return false;
};

export default ({ $http }) => ({
  // get Policy by name
  async getPoliciesByName(context, variables ) {
    console.log('variables', variables)
    if (checkAccess(context, 6, 'read')) return true;
    const instance = $http.create({
      baseURL: ENVURL,
    });
    const { data } = await instance.post('', { query: getPolicyQueryName, variables });
    context.commit('setPoliciesNameInStore', data?.data || []);
    return data?.data;
  },
  // get all skills
  async getPolicies(context, variables ) {
    if (checkAccess(context, 6, 'read')) return true;
    const instance = $http.create({
      baseURL: ENVURL,
    });
    const { data } = await instance.post('', { query: getPoliciesQuery, variables });
    context.commit('setPoliciesInStore', data?.data || []);
    return data?.data;
  },
  // create policy
  async createPolicy(context, variables) {
    if (checkAccess(context, 6, 'create')) return true;
    const instance = $http.create({
      baseURL: ENVURL,
    });
    const { data } = await instance.post('', { query: createPolicyQuery, variables });
    return data?.data?.PoliciesUpdate;
  },
  // update policy
  async updatePolicy(context, variables) {
    if (checkAccess(context, 6, 'update')) return true;
    const instance = $http.create({
      baseURL: ENVURL,
    });
    const { data } = await instance.post('', { query: updatePolicyQuery, variables });
    return data?.data?.PoliciesUpdate;
  },
  // update policy status
  async updatePolicyStatus(context, variables) {
    if (checkAccess(context, 6, 'update')) return true;
    const instance = $http.create({
      baseURL: ENVURL,
    });
    const { data } = await instance.post('', { query: updatePolicyStatusQuery, variables });
    return data?.data?.PoliciesUpdate;
  },
  // Refresh policy
  async refreshPolicy(context, uuid) {
    if (checkAccess(context, 6, 'update')) return true;
    const url = window.location.origin.includes('admin.genome.genpact.com')
      ? `https://api.genpact.com/prod/genome/data-hose/updateIdealProfileByPolicies/${uuid}`
      : `https://api.genpact.com/uat/genome/data-hose/updateIdealProfileByPolicies/${uuid}`;
    const data = await fetch(url)
      .then((response) => response.json())
      .then((resp) => JSON.stringify(resp));
    return data;
  },
});

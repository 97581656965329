<template>
  <div v-if="inputData?.length" class="relative">
    <div class="flex items-center justify-center">
      <button @click="openPopper = !openPopper" class="font-medium rounded-lg text-sm text-center inline-flex items-center" type="button">
        <FilterIcon size="1x" :fill="selectedVal?.length ? '#5a5a5a' : '#fff'" class="mx-1 cursor-pointer" />
      </button>

      <!-- Dropdown menu -->
      <div v-show="openPopper" class="absolute z-10 w-64 p-3 m-0 bg-white rounded-lg shadow block" data-popper-placement="bottom" style="inset: 0px auto auto 0px; transform: translate3d(-4px, 28px, 0px)">
        <div class="absolute left-0 top-0 m-1 border-b-white border-[12px] -mt-3 border-t-0 border-l-transparent border-t-transparent border-r-transparent" />

        <div class="flex justify-between items-center">
          <h6 class="mb-3 text-sm font-medium text-gray-900">
            Filters
          </h6>

          <button @click="selectedVal = []" class="mb-3 text-xs font-medium text-genome text-genome-aqua-blue">
            Clear All
          </button>
        </div>

        <div class="pb-2 relative mx-auto text-gray-600">
          <input v-model="searchVal" class="w-full border border-gray-300 bg-white h-8 px-4 pr-10 rounded-lg text-sm focus:outline-none" type="search" placeholder="Search">

          <button type="submit" class="absolute right-0 top-0 mt-2 mr-4 text-gray-500">
            <svg
              class="text-gray-600 h-4 w-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              id="Capa_1"
              x="0px"
              y="0px"
              viewBox="0 0 56.966 56.966"
              style="enable-background: new 0 0 56.966 56.966"
              xml:space="preserve"
              width="512px"
              height="512px"
            >
              <!-- eslint-disable-next-line max-len -->
              <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
            </svg>
          </button>
        </div>

        <h6 class="mb-3 text-sm font-medium text-gray-900 border-b pb-2">
          {{ name }}
        </h6>

        <ul class="space-y-2 text-sm font-normal overflow-y-auto h-40 border-b mb-2 pb-2">
          <li v-for="(option, i) in filteredOptions" :key="i" class="flex items-center">
            <input :id="`${option[showField]}_${name}_${i}`" type="checkbox" :value="option" v-model="selectedVal" class="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600">
            <label :for="`${option[showField]}_${name}_${i}`" class="ml-2 text-sm text-gray-900 cursor-pointer">{{ option[showField] }}</label>
          </li>
        </ul>

        <div class="flex">
          <button @click="openPopper = false" class="flex px-2.5 py-1 text-sm font-medium text-white bg-genome-dark-blue bg-ge rounded-lg border border-genome-dark-blue hover:bg-blue-800 focus:outline-none">
            Cancel
          </button>
          <button
            @click="$emit('filteredData', selectedVal?.length ? selectedVal : null), (openPopper = false)"
            class="flex px-2.5 py-1 ml-auto text-sm font-medium text-white bg-genome-dark-blue bg-ge rounded-lg border border-genome-dark-blue hover:bg-blue-800 focus:outline-none"
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterIcon } from 'vue-feather-icons';

export default {
  name: 'FilterComp',
  components: {
    FilterIcon,
  },
  props: {
    inputData: {
      type: Array,
      default: () => [],
      required: true,
    },
    name: {
      type: String,
      default: 'Filter Options',
      required: true,
    },
    showField: {
      type: String,
      default: 'id',
      required: true,
    },
  },
  data() {
    return {
      searchVal: '',
      selectedVal: [],
      openPopper: false,
    };
  },
  computed: {
    filteredOptions() {
      if (!this.searchVal) return this.inputData;
      return this.inputData.filter((option) => option[this.showField]?.toLowerCase()?.includes(this.searchVal?.toLowerCase()));
    },
  },
};
</script>

import skillsActionModule from './persona-actions';

const initializeState = () => ({
  personas: [],
});

export default ({ $http }) => ({
  state: {
    ...initializeState(),
  },
  mutations: {
    setPersonasInStore(state, payload) {
      state.personas = payload || [];
    },
  },
  actions: skillsActionModule({ $http }),
  getters: {
    personas: (state) => state.personas,
  },
});

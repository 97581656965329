import skillChannelActionModule from './skillChannel-action';

const initializeState = () => ({
  
  skillchannel: {  },
  subskillchannel: [],
});

export default ({ $http }) => ({
  state: {
    ...initializeState(),
  },
  mutations: {
    setSubSkillChannelInStore(state, payload){
      state.subskillchannel = payload || [];
    },
    setSkillChannelInStore(state, payload) {
      state.skillchannel = payload || { };
      if(state.skillchannel){
        payload.category.map(s => (s.key = 'category', s));
        payload.skill.map(s => (s.key = 'skillName', s));
        payload.channel.map(s => (s.key = 'channelName', s));
        payload.badge.map(s => (s.key = 'badgeName', s));
      }
    },
  },
  actions: skillChannelActionModule({ $http }),
  getters: {
    skillChannel: (state) => state.skillchannel,
    subSkillChannel: (state) => state.subskillchannel,
  },
});

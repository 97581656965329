const baseUrl = window.location.origin.includes('admin.genome.genpact.com') ? 'https://api.genpact.com/prod/genome' : 'https://genome-dev.genpact.com/api';

const checkAccess = (context, page, actionName) => {
  const accessJson = JSON.parse(localStorage.getItem('rbac_user'));
  const findObj = accessJson.pages.find((obj) => obj.code === page) || {};
  const userHasAccess = 'permission' in findObj && findObj.permission.some((objx) => objx === actionName);
  if (!userHasAccess) {
    context.dispatch('addToastNotifications', {
      text: "You don't have permission to perform this action!",
      type: 'error',
      timer: 3000,
    });
    return true;
  }
  return false;
};

export default ({ $http }) => ({
  // get all personas
  async getAllPersonas(context, data) {
    if (checkAccess(context, 14, 'read')) return true;
    const config = {
      method: 'POST',
      url: `${baseUrl}/my-profile/persona/admin/use-case`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    await $http
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        context.commit('setPersonasInStore', response?.data || {});
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  // update persona
  async updatePersona(context, data) {
    if (checkAccess(context, 14, 'update')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/my-profile/persona/update/use-case`,
      headers: {
        'content-type': 'application/json',
      },
      data,
    };
    const personaData = await $http
      .request(config)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return personaData;
  },
});

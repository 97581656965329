import academyActionModule from './academy-actions';

const initializeState = () => ({
  academy: {},
  module: {},
  categoryListByAcademy: {},
  learners: {},
  academy1: [],
  enrollment: {},
  functionandrole:{}
});

export default ({ $http }) => ({
  state: {
    ...initializeState(),
  },
  mutations: {
    setAcademyListInStore(state, payload){
      state.academy = payload || {}
    },
    setModuleListInStore(state, payload){
      state.module = payload || {}
    },
    setCategoryListInStore(state, payload)
    {
      state.categoryListByAcademy = payload || []
    },
    setLearnerListInStore(state, payload)
    {
      state.learners = payload || []
    },
    setAcademyListInStore1(state, payload){
      state.academy1 = payload || {}
    },
    setEnrollmentStatusListInStore1(state, payload){
      state.enrollment = payload || {}
    },
    setFunctionAndRoleListInStore(state, payload)
    {
      state.functionandrole = payload || {}
    }
  },
  actions: academyActionModule({ $http }),
  getters: {
    academy: (state) => state.academy  || {},
    module: (state) => state.module || {},
    categoryListByAcademy: (state) => state.categoryListByAcademy  || {},
    learners: (state) => state.learners  || {},
    academy1: (state) => state.academy1  || [],
    enrollment: (state) => state.enrollment  || {},
    functionandrole: (state) => state.functionandrole  || {}
  },
});

// import axios from 'axios';
import { /* addSkillsQuery, getAllSkillsQuery, updateSkillsQuery, */ getAcrhetypeQuery, updateArchetypeQuery } from '../queries';

// eslint-disable-next-line operator-linebreak
const ENVURL = window.location.origin.includes('admin.genome.genpact.com') ? 'https://api.genpact.com/prod/genome/my-profile/graphql' : 'https://genome-dev.genpact.com/api/my-profile/graphql';

const checkAccess = (context, page, actionName) => {
  const accessJson = JSON.parse(localStorage.getItem('rbac_user'));
  const findObj = accessJson.pages.find((obj) => obj.code === page) || {};
  const userHasAccess = 'permission' in findObj && findObj.permission.some((objx) => objx === actionName);
  // alert(JSON.stringify(userHasAccess))
  if (!userHasAccess) {
    context.dispatch('addToastNotifications', {
      text: "You don't have permission to perform this action!",
      type: 'error',
      timer: 3000,
    });
    return true;
  }
  return false;
};

export default ({ $http }) => ({
  // get all Archetype
  async getArchetype(context, { page, variables = {} }) {
    if (checkAccess(context, page, 'read')) return true;
    const instance = $http.create({
      baseURL: ENVURL,
    });
    // delete instance.defaults.headers.common.Authorization;
    const { data } = await instance.post('', { query: getAcrhetypeQuery, variables });
    context.commit('setArchetypeInStore', data?.data || {});
    return data?.data;
  },
  // get all skills
  // async getSkills(context, variables = {}) {
  //   if (checkAccess(context, 7, 'read')) return true;
  //   const instance = $http.create({
  //     baseURL: ENVURL,
  //   });
  //   // delete instance.defaults.headers.common.Authorization;
  //   const { data } = await instance.post('', { query: getAllSkillsQuery, variables });
  //   context.commit('setSkillsByMastersInStore', data?.data?.Group || {});
  //   return data.Group;
  // },
  // create archetype
  async addArchetype(context, variables) {
    if (checkAccess(context, 7, 'create')) return true;
    const instance = $http.create({
      baseURL: ENVURL,
    });
    // delete instance.defaults.headers.common.Authorization;
    const { data } = await instance.post('', { query: updateArchetypeQuery, variables });
    return data;
  },
  // update archetype
  async updateArchetype(context, variables) {
    if (checkAccess(context, 7, 'update')) return true;
    const instance = $http.create({
      baseURL: ENVURL,
    });
    // delete instance.defaults.headers.common.Authorization;
    const { data } = await instance.post('', { query: updateArchetypeQuery, variables });
    return data;
  },
});

import axios from 'axios';
const ENVURL = window.location.origin.includes('admin.genome.genpact.com') ? 'https://api.genpact.com/prod/genome/data-hose' : 'https://655s39d7e4.execute-api.ap-south-1.amazonaws.com/api/dcl';
const signedURLMDM = window.location.origin.includes('admin.genome.genpact.com') ? 'https://api.genpact.com/prod/genome/data-hose' : 'https://api.genpact.com/uat/genome/data-hose';
const bucketName = window.location.origin.includes('admin.genome.genpact.com') ? 'core-genome-data-lake-raw-aps1-prod' : 'core-genome-data-lake-raw-aps1-poc';

const createUUID = () => {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

const checkAccess = (context, page, actionName) => {
  const accessJson = JSON.parse(localStorage.getItem('rbac_user'));
  const findObj = accessJson.pages.find((obj) => obj.code === page) || {};
  const userHasAccess = 'permission' in findObj && findObj.permission.some((objx) => objx === actionName);
  // alert(JSON.stringify(userHasAccess))
  if (!userHasAccess) {
    context.dispatch('addToastNotifications', {
      text: "You don't have permission to perform this action!",
      type: 'error',
      timer: 3000,
    });
    return true;
  }
  return false;
};

export default ({ $http }) => ({
  // get all folders
  async getAllFolders(context, data) {
    if (checkAccess(context, 12, 'read')) return true;
    context.commit('setAllFoldersInStore', {});
    return await context.dispatch('fetchFolders', data);
  },
  async fetchFolders(context, data) {
    const config = {
      method: 'post',
      url: `${ENVURL}/folder/list`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    await $http
      .request(config)
      .then((response) => {
        context.commit('setAllFoldersInStore', response?.data || {});
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  },

  // create/ update folder
  async createUpdateFolder(context, data) {
    if (checkAccess(context, 12, 'create') || checkAccess(context, 12, 'update')) return true;
    const config = {
      method: 'post',
      url: `${ENVURL}/folder/createupdate`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    const response = await $http
      .request(config)
      .then((response) => {
        context.dispatch('fetchFolders', {
          search: '',
          orderBy: {
            key: 'updatedAt',
            type: 'desc',
          },
          offset: 0,
          limit: 20,
        });
        return response;
      })
      .catch((error) => {
        context.dispatch('addToastNotifications', {
          text: 'Something went wrong! Please try again!',
          type: 'error',
          timer: 4000,
        });
        return error;
      });
    return response;
  },

  // rename file
  async renameFileObj(context, { data, parent }) {
    if (checkAccess(context, 12, 'update')) return true;
    const config = {
      method: 'post',
      url: `${ENVURL}/document/rename`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    const response = await $http
      .request(config)
      .then((response) => {
        context.dispatch('getAllFiles', {
          filter: [
            {
              key: 'folderId',
              value: [parent] || null,
            },
          ],
          search: '',
          orderBy: {
            key: 'updatedAt',
            type: 'desc',
          },
          offset: 0,
          limit: 20,
        });
        return response;
      })
      .catch((error) => {
        context.dispatch('addToastNotifications', {
          text: 'Something went wrong! Please try again!',
          type: 'error',
          timer: 4000,
        });
        return error;
      });
    return response;
  },

  // rename file
  async moveFileObj(context, data) {
    if (checkAccess(context, 12, 'update')) return true;
    const config = {
      method: 'post',
      url: `${ENVURL}/document/move`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    const response = await $http
      .request(config)
      .then((response) => {
        context.dispatch('getAllFiles', {
          filter: [
            {
              key: 'folderId',
              value: [data?.folderId] || null,
            },
          ],
          search: '',
          orderBy: {
            key: 'updatedAt',
            type: 'desc',
          },
          offset: 0,
          limit: 20,
        });
        return response;
      })
      .catch((error) => {
        context.dispatch('addToastNotifications', {
          text: 'Something went wrong! Please try again!',
          type: 'error',
          timer: 4000,
        });
        return error;
      });
    return response;
  },

  // publish/unpublish file
  async publishFile(context, data) {
    if (checkAccess(context, 12, 'delete')) return true;
    const config = {
      method: 'post',
      url: `${ENVURL}/document/updatestatus`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    const response = await $http
      .request(config)
      .then((response) => {
        const filter = [
          {
            key: 'folderId',
            value: [data?.folderId] || null,
          },
        ];
        const payload = {
          search: '',
          orderBy: {
            key: 'updatedAt',
            type: 'desc',
          },
          offset: 0,
          limit: 20,
        };
        if (data?.contentType === 'folder') context.dispatch('getAllFolders', payload);
        else context.dispatch('getAllFiles', { ...payload, filter });
        if (response?.data?.response?.message)
          context.dispatch('addToastNotifications', {
            text: response?.data?.response?.message,
            type: 'success',
            timer: 4000,
          });
        return response?.data;
      })
      .catch((error) => {
        context.dispatch('addToastNotifications', {
          text: 'Something went wrong! Please try again!',
          type: 'error',
          timer: 4000,
        });
        return error;
      });
    return response;
  },

  // delete file/folder
  async deleteFileFolder(context, data) {
    if (checkAccess(context, 12, 'delete')) return true;
    const config = {
      method: 'delete',
      url: `${ENVURL}/${data?.contentType}/${data?.id}`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
    };
    const response = await $http
      .request(config)
      .then((response) => {
        const filter = [
          {
            key: 'folderId',
            value: [data?.folderId] || null,
          },
        ];
        const payload = {
          search: '',
          orderBy: {
            key: 'updatedAt',
            type: 'desc',
          },
          offset: 0,
          limit: 20,
        };
        if (data?.contentType === 'folder') context.dispatch('getAllFolders', payload);
        else context.dispatch('getAllFiles', { ...payload, filter });
        if (response?.data?.response?.message)
          context.dispatch('addToastNotifications', {
            text: response?.data?.response?.message,
            type: 'success',
            timer: 4000,
          });
        return response?.data;
      })
      .catch((error) => {
        context.dispatch('addToastNotifications', {
          text: 'Something went wrong! Please try again!',
          type: 'error',
          timer: 4000,
        });
        return error;
      });
    return response;
  },

  // get all files
  async getAllFiles(context, data) {
    if (checkAccess(context, 12, 'read')) return true;
    context.commit('setAllFilesInStore', {});
    const config = {
      method: 'post',
      url: `${ENVURL}/document/list`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    await $http
      .request(config)
      .then((response) => {
        context.commit('setAllFilesInStore', response?.data || {});
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  },

  // get signed url
  async getSignedUrlForDCL(context, { path, action }) {
    const data1 = JSON.stringify({
      bucketName,
      path,
      action,
    });
    const instance = $http.create({
      baseURL: `${signedURLMDM}/getSignedUrl`,
      headers: { 'Content-Type': 'application/json' },
    });
    const { data } = await instance.post('', data1, {
      requestName: 'getSignedUrl',
    });
    return data;
  },

  // upload file functionality
  async uploadFileToDclS3(context, { fileObj, path, folderId, fileId, isPublic }) {
    if (checkAccess(context, 12, 'create')) return true;
    // const file = await fetch(fileObj.blob)
    //   .then((r) => r.blob())
    //   .then((blobFile) => new File([blobFile], fileObj.name, { type: blobFile.type }));
    const file = fileObj;
    const newUuid = createUUID();
    const fileName = newUuid + `.${file?.type?.split('/')?.pop()}`;
    const newPath = path + `/${fileName}`;
    
    try {
      // --------------- get signed url ----------------------------
      const data = await context.dispatch('getSignedUrlForDCL', {
        path: fileId ? path : newPath,
        action: 'upload',
      });
      try {
        // --------------- upload file ----------------------------
        const instance1 = $http.create({
          baseURL: data?.url,
          headers: { 'Content-Type': file?.type },
        });
        delete instance1.defaults.headers.common.Authorization;
        const response = await instance1.put('', file, {
          requestName: 'uploadFileToS3',
        });
        try {
          // --------------- add details in db----------------------------
          if (response.status === 200) {
            const data2 = JSON.stringify({
              id: fileId ? fileId : newUuid,
              name: file?.name || fileName,
              type: file?.type?.split('/')?.pop(),
              folderId,
              description: '',
              asset_url: '',
              raw_url: fileId ? path : newPath,
              meta: { fileData: JSON.stringify(fileObj) },
              status: file?.type?.split('/')?.pop() === 'pdf' ? true : false,
              deleteStatus: false,
              isPublic,
            });
            const config2 = {
              method: 'post',
              maxBodyLength: Infinity,
              url: `${ENVURL}/document/createupdate`,
              headers: {
                'Content-Type': 'application/json',
              },
              data: data2,
            };

            await axios
              .request(config2)
              .then((response) => {
                // console.log(JSON.stringify(response?.data?.response?.message));
                if (response?.data?.response?.message === 'document exists cant be updated !')
                  context.dispatch('addToastNotifications', {
                    text: 'File already exist with same name',
                    timer: 4000,
                    type: 'error',
                  });
                else
                  context.dispatch('addToastNotifications', {
                    text: response?.data?.response?.message,
                    timer: 4000,
                  });
                context.dispatch('getAllFiles', {
                  search: '',
                  filter: [
                    {
                      key: 'folderId',
                      value: [folderId],
                    },
                  ],
                  orderBy: {
                    key: 'updatedAt',
                    type: 'desc',
                  },
                  offset: 0,
                  limit: 20,
                });
                return response;
              })
              .catch((error) => {
                context.dispatch('addToastNotifications', {
                  text: error.message || 'Something went wrong! Please try again!',
                  type: 'error',
                  timer: 4000,
                });
                return error;
              });
          }
        } catch (error) {
          context.dispatch('addToastNotifications', {
            text: 'Something went wrong! Please try again!',
            type: 'error',
            timer: 4000,
          });
          return error;
        }
      } catch (error) {
        context.dispatch('addToastNotifications', {
          text: 'Something went wrong! Please try again!',
          type: 'error',
          timer: 4000,
        });
        return error;
      }
    } catch (error) {
      context.dispatch('addToastNotifications', {
        text: 'Something went wrong! Please try again!',
        type: 'error',
        timer: 4000,
      });
      return error;
    }
  },
});

const ENVURL = window.location.origin.includes('admin.genome.genpact.com') ? 'https://genome.genpact.com' : 'https://genome-dev.genpact.com';

const checkAccess = (context, page, actionName) => {
  const accessJson = JSON.parse(localStorage.getItem('rbac_user'));
  const findObj = accessJson.pages.find((obj) => obj.code === page) || {};
  const userHasAccess = 'permission' in findObj && findObj.permission.some((objx) => objx === actionName);
  // alert(JSON.stringify(userHasAccess))
  if (!userHasAccess) {
    context.dispatch('addToastNotifications', {
      text: "You don't have permission to perform this action!",
      type: 'error',
      timer: 3000,
    });
    return true;
  }
  return false;
};

const getData = async (context, { $http, variables }) => {
  if (checkAccess(context, 13, 'read')) return true;
  const instance = $http.create({
    baseURL: `${ENVURL}/api/linkedin/list`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  // delete instance.defaults.headers.common.Authorization;
  const { data } = await instance.post('', variables);
  return data;
};

export default ({ $http }) => ({
  async getFiltersData(context) {
    if (checkAccess(context, 13, 'read')) return true;
    const instance = $http.create({
      baseURL: `${ENVURL}/api/linkedin/linkedInCardsMaster`,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    // delete instance.defaults.headers.common.Authorization;
    const { data } = await instance.post('');
    // alert(JSON.stringify(data))
    context.commit('setCardsMaster', data);
    return data;
  },

  // learning activities
  async getLearningActivityList(context, variables) {
    if (checkAccess(context, 13.1, 'read')) return true;
    const instance = $http.create({
      baseURL: `${ENVURL}/api/linkedin/learningActivityList`,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const { data } = await instance.post('', variables);
    context.commit('setLearningActivityList', data || {});
    return data;
  },

  // Job execution history
  async getJobExecutionList(context, variables) {
    if (checkAccess(context, 13.2, 'read')) return true;
    const instance = $http.create({
      baseURL: `${ENVURL}/api/linkedin/ScheduledJobsExecution`,
      // baseURL: `https://jtk79wht5f.execute-api.ap-south-1.amazonaws.com/linkedin/ScheduledJobsExecution`,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const { data } = await instance.post('', variables);
    context.commit('setJobExecHistoryList', data);
    return data;
  },

  // Run Job execution
  async runJobExecution(context, variables) {
    if (checkAccess(context, 13.2, 'create')) return true;
    const instance = $http.create({
      baseURL: `${ENVURL}/api/linkedin/linkedInDataSyncJobsAPI`,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const { data } = await instance.post('', variables);
    return data;
  },

  // Trigger download link to email
  async contSearchDownload(context, variables) {
    if (checkAccess(context, 13, 'read')) return true;
    const instance = $http.create({
      baseURL: `https://api.genpact.com/${window.location.origin.includes('admin.genome.genpact.com') ? 'prod' : 'uat'}/genome/data-hose/downloadfile`,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const { data } = await instance.post('', variables);
    return data;
  },

  // LinkedIn Content Search
  async getLinkedInContent(context, variables) {
    context.commit('setLinkedInContent', {});
    const data = await getData(context, { $http, variables });
    context.commit('setLinkedInContent', data || {});
  },

  // Chapter wise Content Search
  async getChapterContent(context, variables) {
    context.commit('setChapterContent', {});
    const data = await getData(context, { $http, variables });
    context.commit('setChapterContent', data || {});
  },

  // Video wise Content Search
  async getVideoContent(context, variables) {
    context.commit('setVideoContent', {});
    const data = await getData(context, { $http, variables });
    context.commit('setVideoContent', data || {});
  },

  // Video wise Content Search
  async clearL2Data(context) {
    context.commit('setChapterContent', {});
  },

  // Video wise Content Search
  async clearL3Data(context) {
    context.commit('setVideoContent', {});
  },
});

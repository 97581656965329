<template>
  <div class="m-4">
    <div class="relative">
      <p v-if="badgeTitle.length" class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-4">
        {{ badgeTitle }}
      </p>
      <select v-model="selectedVal" class="focus:outline-none block w-full h-full pl-4 py-2 pr-8 text-sm font-medium leading-tight text-gray-700 bg-gray-100 border rounded appearance-none">
        <option default :value="null">
          -- Select Archetype --
        </option>
        <option v-for="(x, index) in dataList" :key="index" :value="x.id" class="">
          {{ x.skillArchetype }}
        </option>
      </select>
      <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
        <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>
    <span v-if="isError" class="text-red-500 text-xs">{{ errorText }}</span>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    badgeTitle: {
      type: String,
      default: '',
    },
    modelName: {
      type: String,
      default: '',
    },
    defaultOption: {
      type: String,
      default: null,
    },
    errorText: {
      type: String,
      default: '',
    },
    dataList: {
      type: Array,
      default: () => [],
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedVal: this.defaultOption,
    };
  },
  mounted() {
    this.$emit('selectedOption', this.selectedVal);
  },
  watch: {
    selectedVal() {
      this.$emit('selectedOption', this.selectedVal);
      // this.$parent[`${this.modelName}`] = this.selectedVal;
      if (this.isError) this.$parent[`${this.modelName}Error`] = false;
    },
    defaultOption(val) {
      this.selectedVal = val;
    },
  },
};
</script>

import axios from 'axios';

const url1 = window.location.origin.includes('admin.genome.genpact.com') ? 'https://api.genpact.com/prod/genome/data-hose' : 'https://api.genpact.com/uat/genome/data-hose';
const bktName = window.location.origin.includes('admin.genome.genpact.com') ? 'core-genome-data-lake-raw-aps1-prod' : 'core-genome-data-lake-raw-aps1-poc';
// const bktName = window.location.origin.includes('admin.genome.genpact.com') ? 'core-genome-data-lake-raw-aps1-prod/raw-data' : 'core-genome-data-lake-raw-aps1-poc/raw-data';
const createUUID = () => {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};
const checkAccess = (context, page, actionName) => {
  const accessJson = JSON.parse(localStorage.getItem('rbac_user'));
  const findObj = accessJson.pages.find((obj) => obj.code === page) || {};
  const userHasAccess = 'permission' in findObj && findObj.permission.some((objx) => objx === actionName);
  // alert(JSON.stringify(userHasAccess))
  if (!userHasAccess) {
    context.dispatch('addToastNotifications', {
      text: "You don't have permission to perform this action!",
      type: 'error',
      timer: 3000,
    });
    return true;
  }
  return false;
};

export default ({ $http }) => ({
  async executeAthenaQuery(context, payload) {
    await $http.post('/athena/v1/query', payload, {
      requestName: 'executeAthenaQuery',
    });
    context.dispatch('addToastNotifications', {
      text: 'Query executed successfully',
      timer: 4000,
    });
    context.dispatch('fetchQueryExecHistory', payload);
  },

  async regenerateReport(context, { id, timestamp }) {
    await $http.put(
      '/athena/v1/query',
      { id, timestamp },
      {
        requestName: 'regenerateReport',
      },
    );
    context.dispatch('addToastNotifications', {
      text: 'Report regeneration request placed successfully',
      timer: 4000,
    });
  },

  async fetchQueryExecHistory(context, payload) {
    let queryString = '';
    Object.keys(payload || {}).forEach((key) => {
      if (payload[key]) queryString += `&${key}=${payload[key]}`;
    });
    try {
      const { data } = await $http.get(`/athena/v1/query?${queryString}`, {
        requestName: 'fetchQueryExecHistory',
      });
      context.commit('setQueryExecHistoryInStore', data);
      return data;
    } catch (error) {
      context.commit('clearRequestState', 'fetchQueryExecHistory');
      return error;
    }
    // const { records } = data;
    // const isAnyReportBeingProcessed = records.find((x) => x.status !== 'completed');
    // console.log(isAnyReportBeingProcessed);
  },

  async uploadFileToS3(context, { fileObj, path, type }) {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    let baseURL;
    const file = await fetch(fileObj.blob)
      .then((r) => r.blob())
      .then((blobFile) => new File([blobFile], fileObj.name, { type: blobFile.type }));
    // const file = fileObj.file;
    // console.log('qq', file, fileObj);
    // const fileObj = new File([file.blob], 'image.png', { type: 'text/csv' });

    if (type === 'immerse') {
      if (path === 'offcon' || path === 'spcl_program_master') {
        const { data } = await $http.get(`/admin/s3/signed-url?path=${path}.csv&action=upload`, {
          requestName: 'uploadFileToS3',
        });
        baseURL = data.url;
      } else if (path === 'bmc') {
        const uuid = createUUID();
        const { data } = await $http.get(`/admin/s3/signed-url?path=${path}/${year}/${month}/${day}/${uuid}.csv&action=upload`, {
          requestName: 'uploadFileToS3',
        });
        baseURL = data.url;
      } else if (path === 'bits_wave_source' || path === 'penetration_mss') {
        const { data } = await $http.get(`/admin/s3/signed-url?path=e360/gpdl/people/manual/${path}/${year}-${month <= 9 ? `0${month}` : month}-${day <= 9 ? `0${day}` : day}.csv&action=upload`, {
          requestName: 'uploadFileToS3',
        });
        baseURL = data.url;
      } else {
        const { data } = await $http.get(`/admin/s3/signed-url?path=${path}/${year}/${month}/${day}.csv&action=upload`, {
          requestName: 'uploadFileToS3',
        });
        baseURL = data.url;
      }
    } 
    else if (type === 'solidify') {
      const { data } = await $http.get(`/admin/s3/signed-url?path=solidify/${path === '/' ? `${file.name}` : `${path}/${file.name}`}&action=upload`, {
        requestName: 'uploadFileToS3',
      });
      baseURL = data.url;
    }
    else if (type === 'events') {
      if (checkAccess(context, 10, 'create')) return true;
      // --------------- get signed url ----------------------------
      const data1 = JSON.stringify({
        bucketName: bktName,
        path: `raw-data/events/${file.name}`,
        action: 'upload',
      });
      const instance = $http.create({
        baseURL: `${url1}/getSignedUrl`,
        headers: { 'Content-Type': 'application/json' },
      });
      const { data } = await instance.post('', data1, {
        requestName: 'getSignedUrl',
      });
      // --------------- upload file ----------------------------
      const instance1 = $http.create({
        baseURL: data.url,
        headers: { 'Content-Type': 'text/csv' },
      });
      delete instance1.defaults.headers.common.Authorization;
      const { data: response } = await instance1.put('', file, {
        requestName: 'uploadFileToS3',
      });
      // --------------- add event details ----------------------------
      const userInfo = JSON.parse(localStorage.getItem('rbac_user'));
      const data2 = JSON.stringify({
        bucketName: bktName,
        name: file.name.split('.csv')[0],
        filename: `raw-data/events/${file.name}`,
        description: file.name,
        type: 'HCT',
        ohrId: userInfo.ohrId,
      });

      const config2 = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${url1}/uploadAdminEvent`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data2,
      };

      axios
        .request(config2)
        .then((response1) => {
          console.log(JSON.stringify(response1.data));
          context.dispatch('addToastNotifications', {
            text: 'File uploaded successfully',
            timer: 4000,
          });
        })
        .catch((error) => {
          console.log(error);
          context.dispatch('addToastNotifications', {
            text: 'Something went wrong!',
            type: 'error',
            timer: 4000,
          });
        });

      return response;
    }
    else if (type === 'persona') {
      if (checkAccess(context, 10, 'create')) return true;
      // --------------- get signed url ----------------------------
      const data1 = JSON.stringify({
        bucketName: bktName,
        path: `raw-data/persona/${file.name}`,
        action: 'upload',
      });
      const instance = $http.create({
        baseURL: `${url1}/getSignedUrl`,
        headers: { 'Content-Type': 'application/json' },
      });
      const { data } = await instance.post('', data1, {
        requestName: 'getSignedUrl',
      });
      // --------------- upload file ----------------------------
      const instance1 = $http.create({
        baseURL: data.url,
        headers: { 'Content-Type': 'text/csv' },
      });
      delete instance1.defaults.headers.common.Authorization;
      const { data: response } = await instance1.put('', file, {
        requestName: 'uploadFileToS3',
      });
      // --------------- add event details ----------------------------
      const userInfo = JSON.parse(localStorage.getItem('rbac_user'));
      const data2 = JSON.stringify({
        bucketName: bktName,
        name: file.name.split('.csv')[0],
        filename: `raw-data/persona/${file.name}`,
        description: file.name,
        type: 'persona',
        ohrId: userInfo.ohrId,
      });

      const config2 = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${url1}/uploadAdminEvent`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data2,
      };

      axios
        .request(config2)
        .then((response1) => {
          console.log(JSON.stringify(response1.data));
          context.dispatch('addToastNotifications', {
            text: 'File uploaded successfully',
            timer: 4000,
          });
        })
        .catch((error) => {
          console.log(error);
          context.dispatch('addToastNotifications', {
            text: 'Something went wrong!',
            type: 'error',
            timer: 4000,
          });
        });

      return response;
    }
    else if (type === 'jobarchitecture') {
      if (checkAccess(context, 11, 'create')) return true;
      // --------------- get signed url ----------------------------
      const data1 = JSON.stringify({
        bucketName: bktName,
        path: `raw-data/jobarchitecture/${file.name}`,
        action: 'upload',
      });
      const instance = $http.create({
        baseURL: `${url1}/getSignedUrl`,
        headers: { 'Content-Type': 'application/json' },
      });
      const { data } = await instance.post('', data1, {
        requestName: 'getSignedUrl',
      });
      // --------------- upload file ----------------------------
      const instance1 = $http.create({
        baseURL: data.url,
        headers: { 'Content-Type': 'text/csv' },
      });
      delete instance1.defaults.headers.common.Authorization;
      const { data: response } = await instance1.put('', file, {
        requestName: 'uploadFileToS3',
      });
      // --------------- add event details ----------------------------
      const userInfo = JSON.parse(localStorage.getItem('rbac_user'));
      const data2 = JSON.stringify({
        bucketName: bktName,
        name: file.name.split('.csv')[0],
        filename: `raw-data/jobarchitecture/${file.name}`,
        description: file.name,
        type: 'jobarchitecture',
        ohrId: userInfo.ohrId,
      });

      const config2 = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${url1}/uploadAdminEvent`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data2,
      };

      axios
        .request(config2)
        .then((response1) => {
          console.log(JSON.stringify(response1.data));
          context.dispatch('addToastNotifications', {
            text: 'File uploaded successfully',
            timer: 4000,
          });
        })
        .catch((error) => {
          console.log(error);
          context.dispatch('addToastNotifications', {
            text: 'Something went wrong!',
            type: 'error',
            timer: 4000,
          });
        });

      return response;
    }
    else if (type === 'roleEvent') {
      if (checkAccess(context, 5, 'create')) return true;
      // --------------- get signed url ----------------------------
      const data1 = JSON.stringify({
        bucketName: bktName,
        path: `raw-data/roleEvent/${file.name}`,
        action: 'upload',
      });
      const instance = $http.create({
        baseURL: `${url1}/getSignedUrl`,
        headers: { 'Content-Type': 'application/json' },
      });
      const { data } = await instance.post('', data1, {
        requestName: 'getSignedUrl',
      });
      // --------------- upload file ----------------------------
      const instance1 = $http.create({
        baseURL: data.url,
        headers: { 'Content-Type': 'text/csv' },
      });
      delete instance1.defaults.headers.common.Authorization;
      const { data: response } = await instance1.put('', file, {
        requestName: 'uploadFileToS3',
      });
      // --------------- add event details ----------------------------
      const userInfo = JSON.parse(localStorage.getItem('rbac_user'));
      const data2 = JSON.stringify({
        bucketName: bktName,
        name: file.name.split('.xlsx')[0],
        filename: `raw-data/roleEvent/${file.name}`,
        description: file.name,
        type: 'roleEvent',
        ohrId: userInfo.ohrId,
      });

      const config2 = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${url1}/uploadAdminEvent`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data2,
      };

      axios
        .request(config2)
        .then((response1) => {
          console.log(JSON.stringify(response1.data));
          context.dispatch('addToastNotifications', {
            text: 'File uploaded successfully',
            timer: 4000,
          });
        })
        .catch((error) => {
          console.log(error);
          context.dispatch('addToastNotifications', {
            text: 'Something went wrong!',
            type: 'error',
            timer: 4000,
          });
        });

      return response;
    }
    else if (type === 'subskill') {
      if (checkAccess(context, 5, 'create')) return true;
      // --------------- get signed url ----------------------------
      const data1 = JSON.stringify({
        bucketName: bktName,
        path: `raw-data/subskill/${file.name}`,
        action: 'upload',
      });
      const instance = $http.create({
        baseURL: `${url1}/getSignedUrl`,
        headers: { 'Content-Type': 'application/json' },
      });
      const { data } = await instance.post('', data1, {
        requestName: 'getSignedUrl',
      });
      // --------------- upload file ----------------------------
      const instance1 = $http.create({
        baseURL: data.url,
        headers: { 'Content-Type': 'text/csv' },
      });
      delete instance1.defaults.headers.common.Authorization;
      const { data: response } = await instance1.put('', file, {
        requestName: 'uploadFileToS3',
      });
      // --------------- add event details ----------------------------
      const userInfo = JSON.parse(localStorage.getItem('rbac_user'));
      const data2 = JSON.stringify({
        bucketName: bktName,
        name: file.name.split('.xlsx')[0],
        filename: `raw-data/subskill/${file.name}`,
        description: file.name,
        type: 'subskill',
        ohrId: userInfo.ohrId,
      });

      const config2 = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${url1}/uploadAdminEvent`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data2,
      };

      axios
        .request(config2)
        .then((response1) => {
          console.log(JSON.stringify(response1.data));
          context.dispatch('addToastNotifications', {
            text: 'File uploaded successfully',
            timer: 4000,
          });
        })
        .catch((error) => {
          console.log(error);
          context.dispatch('addToastNotifications', {
            text: 'Something went wrong!',
            type: 'error',
            timer: 4000,
          });
        });

      return response;
    }
    else if (type === 'idealprofile') {
      if (checkAccess(context, 6, 'create')) return true;
      // --------------- get signed url ----------------------------
      const data1 = JSON.stringify({
        bucketName: bktName,
        path: `raw-data/idealprofile/${file.name}`,
        action: 'upload',
      });
      const instance = $http.create({
        baseURL: `${url1}/getSignedUrl`,
        headers: { 'Content-Type': 'application/json' },
      });
      const { data } = await instance.post('', data1, {
        requestName: 'getSignedUrl',
      });
      // --------------- upload file ----------------------------
      const instance1 = $http.create({
        baseURL: data.url,
        headers: { 'Content-Type': 'text/csv' },
      });
      delete instance1.defaults.headers.common.Authorization;
      const { data: response } = await instance1.put('', file, {
        requestName: 'uploadFileToS3',
      });
      // --------------- add event details ----------------------------
      const userInfo = JSON.parse(localStorage.getItem('rbac_user'));
      const data2 = JSON.stringify({
        bucketName: bktName,
        name: file.name.split('.xlsx')[0],
        filename: `raw-data/idealprofile/${file.name}`,
        description: file.name,
        type: 'idealprofile',
        ohrId: userInfo.ohrId,
      });

      const config2 = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${url1}/uploadAdminEvent`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data2,
      };

      axios
        .request(config2)
        .then((response1) => {
          console.log(JSON.stringify(response1.data));
          context.dispatch('addToastNotifications', {
            text: 'File uploaded successfully',
            timer: 4000,
          });
        })
        .catch((error) => {
          console.log(error);
          context.dispatch('addToastNotifications', {
            text: 'Something went wrong!',
            type: 'error',
            timer: 4000,
          });
        });

      return response;
    }
    else if (type === 'coreskill') {
      if (checkAccess(context, 10, 'create')) return true;
      // --------------- get signed url ----------------------------
      const data1 = JSON.stringify({
        bucketName: bktName,
        path: `raw-data/criticalcore/${file.name}`,
        action: 'upload',
      });
      const instance = $http.create({
        baseURL: `${url1}/getSignedUrl`,
        headers: { 'Content-Type': 'application/json' },
      });
      const { data } = await instance.post('', data1, {
        requestName: 'getSignedUrl',
      });
      // --------------- upload file ----------------------------
      const instance1 = $http.create({
        baseURL: data.url,
        headers: { 'Content-Type': 'text/csv' },
      });
      delete instance1.defaults.headers.common.Authorization;
      const { data: response } = await instance1.put('', file, {
        requestName: 'uploadFileToS3',
      });
      // --------------- add event details ----------------------------
      const userInfo = JSON.parse(localStorage.getItem('rbac_user'));
      const data2 = JSON.stringify({
        bucketName: bktName,
        name: file.name.split('.csv')[0],
        filename: `raw-data/criticalcore/${file.name}`,
        description: file.name,
        type: 'coreskill',
        ohrId: userInfo.ohrId,
      });

      const config2 = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${url1}/uploadAdminEvent`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data2,
      };

      axios
        .request(config2)
        .then((response1) => {
          console.log(JSON.stringify(response1.data));
          context.dispatch('addToastNotifications', {
            text: 'File uploaded successfully',
            timer: 4000,
          });
        })
        .catch((error) => {
          console.log(error);
          context.dispatch('addToastNotifications', {
            text: 'Something went wrong!',
            type: 'error',
            timer: 4000,
          });
        });

      return response;
    }
    else if (type === 'criticalskill') {
      if (checkAccess(context, 10, 'create')) return true;
      // --------------- get signed url ----------------------------
      const data1 = JSON.stringify({
        bucketName: bktName,
        path: `raw-data/criticalskill/${file.name}`,
        action: 'upload',
      });
      const instance = $http.create({
        baseURL: `${url1}/getSignedUrl`,
        headers: { 'Content-Type': 'application/json' },
      });
      const { data } = await instance.post('', data1, {
        requestName: 'getSignedUrl',
      });
      // --------------- upload file ----------------------------
      const instance1 = $http.create({
        baseURL: data.url,
        headers: { 'Content-Type': 'text/csv' },
      });
      delete instance1.defaults.headers.common.Authorization;
      const { data: response } = await instance1.put('', file, {
        requestName: 'uploadFileToS3',
      });
      // --------------- add event details ----------------------------
      const userInfo = JSON.parse(localStorage.getItem('rbac_user'));
      const data2 = JSON.stringify({
        bucketName: bktName,
        name: file.name.split('.csv')[0],
        filename: `raw-data/criticalskill/${file.name}`,
        description: file.name,
        type: 'criticalskill',
        ohrId: userInfo.ohrId,
      });

      const config2 = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${url1}/uploadAdminEvent`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data2,
      };

      axios
        .request(config2)
        .then((response1) => {
          console.log(JSON.stringify(response1.data));
          context.dispatch('addToastNotifications', {
            text: 'File uploaded successfully',
            timer: 4000,
          });
        })
        .catch((error) => {
          console.log(error);
          context.dispatch('addToastNotifications', {
            text: 'Something went wrong!',
            type: 'error',
            timer: 4000,
          });
        });

      return response;
    } 
    else if (type === 'enrollmentRequest') {
      console.log("type :: enrollmentrequest")
      if (checkAccess(context, 10, 'create')) return true;

      // --------------- get signed url ----------------------------
      const data1 = JSON.stringify({
        bucketName: bktName,
        path: `raw-data/academy/learners_enrollment/${file.name}`,
        action: 'upload',
      });
      const instance = $http.create({
        baseURL: `${url1}/getSignedUrl`,
        headers: { 'Content-Type': 'application/json' },
      });
      const { data } = await instance.post('', data1, {
        requestName: 'getSignedUrl',
      });

       // --------------- upload file ----------------------------
       const instance1 = $http.create({
        baseURL: data.url,
        headers: { 'Content-Type': 'text/csv' },
      });
      delete instance1.defaults.headers.common.Authorization;
      const { data: response } = await instance1.put('', file, {
        requestName: 'uploadFileToS3',
      });

      // --------------- add event details ----------------------------
      const userInfo = JSON.parse(localStorage.getItem('rbac_user'));
      const data2 = JSON.stringify({
        bucketName: bktName,
        name: file.name.split('.csv')[0],
        filename: `raw-data/academy/learners_enrollment/${file.name}`,
        description: file.name,
        type: 'enrollmentRequest',
        ohrId: userInfo.ohrId,
      });

      const config2 = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${url1}/uploadAdminEvent`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data2,
      };

      axios
        .request(config2)
        .then((response1) => {
          console.log(JSON.stringify(response1.data));
          context.dispatch('addToastNotifications', {
            text: 'File uploaded successfully',
            timer: 4000,
          });
        })
        .catch((error) => {
          console.log(error);
          context.dispatch('addToastNotifications', {
            text: 'Something went wrong!',
            type: 'error',
            timer: 4000,
          });
        });

      return response;




    }
    else if (type === 'functionRoleMapping') {
      console.log("type === 'functionRoleMapping");
      if (checkAccess(context, 10, 'create')) return true;
      // --------------- get signed url ----------------------------
      const data1 = JSON.stringify({
        bucketName: bktName,
        path: `raw-data/functionRoleMapping/${file.name}`,
        action: 'upload',
      });
      const instance = $http.create({
        baseURL: `${url1}/getSignedUrl`,
        headers: { 'Content-Type': 'application/json' },
      });
      const { data } = await instance.post('', data1, {
        requestName: 'getSignedUrl',
      });
      // --------------- upload file ----------------------------
      const instance1 = $http.create({
        baseURL: data.url,
        headers: { 'Content-Type': 'text/csv' },
      });
      delete instance1.defaults.headers.common.Authorization;
      const { data: response } = await instance1.put('', file, {
        requestName: 'uploadFileToS3',
      });
      // --------------- add event details ----------------------------
      const userInfo = JSON.parse(localStorage.getItem('rbac_user'));
      const data2 = JSON.stringify({
        bucketName: bktName,
        name: file.name.split('.csv')[0],
        filename: `raw-data/functionRoleMapping/${file.name}`,
        description: file.name,
        type: 'functionRoleMapping',
        ohrId: userInfo.ohrId,
      });

      const config2 = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${url1}/uploadAdminEvent`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data2,
      };

      axios
        .request(config2)
        .then((response1) => {
          console.log(JSON.stringify(response1.data));
          context.dispatch('addToastNotifications', {
            text: 'File uploaded successfully',
            timer: 4000,
          });
        })
        .catch((error) => {
          console.log(error);
          context.dispatch('addToastNotifications', {
            text: 'Something went wrong!',
            type: 'error',
            timer: 4000,
          });
        });

      return response;
    }

    const instance = $http.create({
      baseURL,
      headers: { 'Content-Type': file.type },
    });

    delete instance.defaults.headers.common.Authorization;
    const { data: response } = await instance.put('', file, {
      requestName: 'uploadFileToS3',
    });

    return response;
  },

  async downloadFileFromS3(context, { file, bucketName }) {
    const { data } = await $http.get(`/admin/s3/signed-url?path=${file}&action=download&bucketName=${bucketName}`, {
      requestName: 'downloadFileFromS3',
    });

    return data.url;
  },

  // async getGroups(context) {
  //   const { data } = await $http.get('/athena/v1/report/special-program', {
  //     requestName: 'getGroups',
  //   });
  //   context.commit('setGroups', data);
  //   return data;
  // },

  async downloadLSFromS3(context, { file, bucketName }) {
    const instance = $http.create({
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Content-Type': 'application/xml',
      },
    });
    const { data } = await instance.get(`https://34dxuhfuyi.execute-api.ap-south-1.amazonaws.com/poc/learner-score-svc/sp/function-get-report?path=${file}&bucketName=genome-data-lake-core-poc-bucket`, {
      requestName: 'downloadLSFromS3',
    });
    // debugger
    console.log(data, 'data', bucketName);
    return data.url;
  },
  async fetchAllReportsLists(context) {
    const { data } = await $http.get('/athena/v1/report', {
      requestName: 'fetchAllReportsLists',
    });

    context.commit('setReportsListsInStore', data);
    return data;
  },

  async updateVersion(context, payload) {
    const { data } = await $http.put(
      // `https://api-v2${window.location.origin.includes('admin.genome.genpact.com') ? '' : '-dev'}.genpact.com/api/releases/mfe/v2/library`,
      `https://${window.location.origin.includes('admin.genome.genpact.com') ? '0e07e0d1xk.execute-api.ap-south-1.amazonaws.com' : '655s39d7e4.execute-api.ap-south-1.amazonaws.com'}/api/releases/mfe/v2/library`,
      {
        customer: 'Genpact',
        appName: 'Genome',
        library: payload.library,
        source: 'npm',
        version: payload.updatedVersion,
        status: true,
      },
      {
        requestName: 'putVersion',
      },
    );
    if (data.message) {
      context.dispatch('addToastNotifications', {
        text: data.message || 'Processing your request',
        timer: 4000,
      });
    }
    return data;
  },

  async getLibraries(context) {
    context.commit('clearLibraries');
    // const { data } = await $http.get(`https://api-v2${window.location.origin.includes('admin.genome.genpact.com') ? '' : '-dev'}.genpact.com/api/releases/mfe/v2/library?cust=genpact&app=genome`, {
    const { data } = await $http.get(
      `https://${window.location.origin.includes('admin.genome.genpact.com') ? '0e07e0d1xk.execute-api.ap-south-1.amazonaws.com' : '655s39d7e4.execute-api.ap-south-1.amazonaws.com'}/api/releases/mfe/v2/library?cust=genpact&app=genome`,
      {
        requestName: 'fetchLibraries',
      },
    );
    if (data && data.length) {
      data.map(async (library) => {
        const libData = await context.dispatch('getVersions', library);
        context.commit('setLibraries', { library, libData });
        return true;
      });
    }
    return data;
  },

  async getVersions(context, obj) {
    // const { data } = await $http.get(`https://api-v2${window.location.origin.includes('admin.genome.genpact.com') ? '' : '-dev'}.genpact.com/api/releases/mfe/v2/library/versions?library=${obj.library}`, {
    const { data } = await $http.get(
      `https://${window.location.origin.includes('admin.genome.genpact.com') ? '0e07e0d1xk.execute-api.ap-south-1.amazonaws.com' : '655s39d7e4.execute-api.ap-south-1.amazonaws.com'}/api/releases/mfe/v2/library/versions?library=${obj.library
      }`,
      {
        requestName: 'fetchVersions',
      },
    );
    return data;
  },

  // fetch Learner Score Reports
  async fetchLSReports(context) {
    const instance = $http.create({
      baseURL: 'https://34dxuhfuyi.execute-api.ap-south-1.amazonaws.com/poc/learner-score-svc/sp/function/LSreportstatus-GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Content-Type': 'application/xml',
      },
    });
    const { data } = await instance.get('', {
      requestName: 'fetchLSReports',
    });

    context.commit('setLSReportsInStore', data);
    return data;
  },
  async executeLSQuery(context, payload) {
    const instance = $http.create({
      baseURL: 'https://34dxuhfuyi.execute-api.ap-south-1.amazonaws.com/poc/learner-score-svc/sp/function/executeLSReport-POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Content-Type': 'application/xml',
      },
    });
    const { data } = await instance.post(
      '',
      {
        query: {
          type: payload,
        },
      },
      {
        requestName: 'executeLSQuery',
      },
    );
    if (data.message) {
      context.dispatch('addToastNotifications', {
        text: 'Processing your report',
        timer: 4000,
      });
    }
    context.dispatch('fetchLSReports', payload);
    return data;
  },
});

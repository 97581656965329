import skillsActionModule from './policy-actions';

const initializeState = () => ({
  policies: [],
  policyName: {},
});

export default ({ $http }) => ({
  state: {
    ...initializeState(),
  },
  mutations: {
    setPoliciesInStore(state, payload) {
      state.policies = payload || [];
    },
    setPoliciesNameInStore(state, payload) {
      state.policyName = payload || {};
    }
  },
  actions: skillsActionModule({ $http }),
  getters: {
    policies: (state) => state.policies,
    policyName: (state) => state.policyName,
  },
});

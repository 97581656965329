import linkedInActionModule from './linkedIn-actions';

const initializeState = () => ({
  linkedInContent: {},
  chapterContent: {},
  videoContent: {},
  cardsMaster: {},
  learningActivityList: {},
  jobExecutionList: [],
});

export default ({ $http }) => ({
  state: {
    ...initializeState(),
  },
  mutations: {
    setLinkedInContent(state, payload) {
      state.linkedInContent = payload;
    },
    setChapterContent(state, payload) {
      state.chapterContent = payload?.data;
    },
    setVideoContent(state, payload) {
      state.videoContent = payload?.data;
    },
    setCardsMaster(state, payload) {
      state.cardsMaster = payload?.data;
    },
    setLearningActivityList(state, payload) {
      state.learningActivityList = payload;
    },
    setJobExecHistoryList(state, payload) {
      state.jobExecutionList = payload?.data;
    },
  },
  actions: linkedInActionModule({ $http }),
  getters: {
    linkedInContent: (state) => state.linkedInContent,
    chapterContent: (state) => state.chapterContent,
    videoContent: (state) => state.videoContent,
    cardsMaster: (state) => state.cardsMaster,
    learningActivityList: (state) => state.learningActivityList,
    jobExecutionList: (state) => state.jobExecutionList,
  },
});

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"search-cont relative flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],ref:_vm.id,class:[
        _vm.withSearchButton ? 'border-r-0 rounded-r-none pl-4 pr-8' : 'pl-10 pr-4',
        _vm.inputClass
          ? _vm.inputClass
          : `
        appearance-none
        block
        w-full
        border
        border-gray-400
        rounded
        py-2
        leading-tight
        focus:outline-none
        bg-gray-100
        text-gray-600
        focus:bg-white
        focus:border-gray-500
    `,
      ],attrs:{"id":_vm.id,"type":"search","placeholder":_vm.placeholder,"autocomplete":"off","spellcheck":"false"},domProps:{"value":(_vm.searchQuery)},on:{"input":[function($event){if($event.target.composing)return;_vm.searchQuery=$event.target.value},_vm.inputChanged],"change":function($event){return _vm.$emit('inputChange', _vm.searchQuery)}}}),(!_vm.withSearchButton)?_c('button',{staticClass:"absolute left-0 inset-y-0 pr-3 mb-1 flex items-center text-gray-600 outline-none focus:outline-none"},[_c('SearchIcon',{staticClass:"text-gray-800",attrs:{"size":"1.2x"}})],1):_vm._e(),_c('transition',{attrs:{"name":"search-close-fade"}},[(_vm.searchQuery)?_c('button',{staticClass:"absolute flex items-center right-0 inset-y-0 px-3 text-gray-600",class:{ 'mr-10': _vm.withSearchButton },on:{"click":_vm.clearSearch}},[_c('XCircleIcon',{attrs:{"size":"1x"}}),_c('transition',{attrs:{"name":"search-close-fade"}},[_c('ring-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataLoading),expression:"dataLoading"}],staticClass:"absolute pin-abs-center",attrs:{"thickness":"2px","width":"20px","height":"20px"}})],1)],1):_vm._e()]),(_vm.withSearchButton)?_c('button',{staticClass:"px-3 flex items-center text-white bg-brand-color border-red-600 border rounded-r-lg",on:{"click":function($event){$event.preventDefault();return _vm.$emit('inputChange', _vm.searchQuery)}}},[_c('XCircleIcon',{attrs:{"size":"1x"}})],1):_vm._e()],1),(!_vm.isTwoChar)?_c('span',{staticClass:"text-sm mb-2 text-gray-500"},[_c('i',[_vm._v("Enter at least 2 characters for search")])]):_vm._e(),(_vm.errorMsg !== '')?_c('div',{staticClass:"flex-col"},[_c('div',{staticClass:"text-lg mt-8 capitalize"},[_c('b',[_vm._v(_vm._s(_vm.myChangedTabName))])]),_c('span',{staticClass:"text-sm mb-2 text-gray-500"},[_vm._v(_vm._s(_vm.errorMsg))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
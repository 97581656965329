export const getSkillsQuery = `query {
  Group(groupId: "95e686e7-5651-4c92-8832-6dda6aec35d5") {
      id
      name
      Levels {
        id
        aka
        name
        code
      }
      Master {
        id
        name
        aka
        description
        code
        MasterHierarchy {
          id
          archetype
          parentId
          message
          mandateData
          status
          addToMyPlan
          KeywordsGroup {
            id
            Keywords{
              id
              name
              description
              status
              updatedAt
            }
          }
        }
      }
    }
  }`;

export const updateArchetypeQuery = `mutation ($data: ArcheTypeInput!){
  ArchetypeUpdate(
    archeTypeInput: $data
  ) {
    id
    code
  }
}`;
export const getAcrhetypeQuery = `query {
  ArcheType(
    orderBy: { code: desc }
    # where: { status: { equals: true } }
  ) {
    id
    code
    archetypeId
    skillArchetype
    definition
    validationMessage
    shortvalidationMessage
    comments
    status
    addToMyPlan
    updatedAt
    updatedBy
    createdAt
    createdBy
    becauseEnable
    immerseEnable
    transformEnable
    solidifyEnable
    immerseSource
    transformSource
    solidifySource
    showDescription
    immerseSource
    transformSource
    solidifySource
  }
}`;

export const addSkillsQuery = `mutation KeywordsUpdate(
    $groupId: String
    $masterId: String!
    $name: String
    $description: String
    $mandateData: JSON
    $parentId: String
    $ohrId: Float!
    $reasonForChange: String
    $skillstock: Boolean
    $status: Boolean
    $defaultSelect: Boolean
    $skillLevelsInput: [skillLevelsInput!]
    ) {
    KeywordsUpdate(
      groupId: $groupId
      ohrId: $ohrId
      masterId: $masterId
      # masterHierarchyId
      keywordUpdateInput: {
        # id
        name: $name
        description: $description
        status: $status
        skillstock: $skillstock
        defaultSelect: $defaultSelect
        mandateData: $mandateData
        parentId: $parentId
        reasonForChange: $reasonForChange
        skillLevelsInput: $skillLevelsInput
      }
    ) {
      id
      name
      KeywordsGroup {
        id
        Keywords {
          id
          name
          description
          status
        }
      }
    }
  }`;

export const updateSkillsQuery = `mutation KeywordsUpdate(
    $groupId: String
    $masterId: String!
    $masterHierarchyId: String
    $skillId: ID
    $name: String
    $description: String
    $status: Boolean
    $skillstock: Boolean
    $defaultSelect: Boolean
    $addToMyPlan: Boolean
    $mandateData: JSON
    $parentId: String
    $ohrId: Float!
    $reasonForChange: String
    $jobArchitectureId: String
    $skillLevelsInput: [skillLevelsInput!]
  ) {
    KeywordsUpdate(
      groupId: $groupId
      masterId: $masterId
      ohrId: $ohrId
      masterHierarchyId: $masterHierarchyId
      keywordUpdateInput: {
        id: $skillId
        name: $name
        description: $description
        status: $status
        skillstock: $skillstock
        defaultSelect: $defaultSelect
        addToMyPlan: $addToMyPlan
        mandateData: $mandateData
        parentId: $parentId
        reasonForChange: $reasonForChange
        jobArchitectureId: $jobArchitectureId
        skillLevelsInput: $skillLevelsInput
      }
    ) {
      id
      name
      KeywordsGroup {
        id
        Keywords {
          id
          name
          description
          status
        }
      }
    }
  }`;
  export const getPolicyQueryName  = `query Policy($mhId: String) {
    Policy(
      where: {
        id: { equals: $mhId }
      }
    ) {
      id
      name
    }
  }`;
export const getPoliciesQuery = `query Policy($skip: Int, $take: Int, $where: PoliciesWhereInput, $search: String  ) {
  PolicyCount(search: $search) {
     count
  }
  Policy(skip: $skip , take: $take, where : $where) {
    id
    code
    name
    aka
    type
    status
    isMandatory
    addTomyPlan
    proficientId
    updatedAt
    updatedBy
    createdAt
    createdBy
    policyCondition
    Levels {
      name
      title
    }
  }
}`;

export const createPolicyQuery = `mutation PoliciesUpdate($isMandatory: Boolean, $proficientId: String, $policyCondition: JSON, $groupId: String, $name: String, $ohrId: String!) {
  PoliciesUpdate(
    policyInput: {
      data: [
        {
          name: $name
          policyCondition: $policyCondition
          isMandatory: $isMandatory
          proficientId: $proficientId
          status: true
          groupId: $groupId
          ohrId: $ohrId
        }
      ]
    }
  ) {
    message
  }
}`;

export const updatePolicyQuery = `mutation PoliciesUpdate($isMandatory: Boolean, $proficientId: String, $policyCondition: JSON, $groupId: String, $name: String, $id: String, $ohrId: String!) {
  PoliciesUpdate(
    policyInput: {
      data: [
        {
          id: $id
          name: $name
          policyCondition: $policyCondition
          isMandatory: $isMandatory
          proficientId: $proficientId
          status: true
          groupId: $groupId
          ohrId: $ohrId
        }
      ]
    }
  ) {
    message
  }
}`;

export const updatePolicyStatusQuery = `mutation PoliciesUpdate($status: Boolean, $id: String, $ohrId: String!) {
  PoliciesUpdate(
    policyInput: {
      data: [
        {
          id: $id
          status: $status
          ohrId: $ohrId
        }
      ]
    }
  ) {
    message
  }
}`;

export const getPoliciesSkillQuery = `query SkillPolicy($policyId: UuidFilter){
  SkillPolicy(
    where: {
      policyId: $policyId
    }
  ) {
    id
    policyId
    status
    updatedAt
    updatedBy
    createdAt
    createdBy
    source
    Policies{
      id
      name
      type
    }
    MasterHierarchy {
      KeywordsGroup {
        Keywords {
          id
          name
        }
      }
    }
  }
}`;

export const getSkillPoliciesQuery = `query SkillPolicy($masterHierarchyId: UuidFilter){
  SkillPolicy(
    where: {
      masterHierarchyId: $masterHierarchyId
    }
  ) {
    id
    policyId
    status
    updatedAt
    updatedBy
    createdAt
    createdBy
    source
    Policies{
      id
      name
      type
    }
    MasterHierarchy {
      KeywordsGroup {
        Keywords {
          name
        }
      }
    }
  }
}`;

export const updateSkillPoliciesQuery = `mutation SkillPoliciesUpdate($data: [SkillPolicyInputDetail!]!){
  SkillPoliciesUpdate(
    skillPolicyInput: {
      data: $data
    }
  ) {
    message
  }
}`;

export const getAllSkillsQuery = `query {
  Group(groupId: "95e686e7-5651-4c92-8832-6dda6aec35d5") {
    id
    name
    Levels {
      id
      aka
      name
      code
    }
    Master {
      id
      name
      aka
      description
      code
      MasterHierarchy {
        id
        archetype
        parentId
        message
        mandateData
        skillstock
        livedAt
        status
        addToMyPlan
        updatedAt
        updatedBy
        createdAt
        createdBy
        KeywordsGroup {
          id
          SkillLevelsRel{
            levelId
            addToMyPlanMessage
            description
            archetypeId
            status
            badgeName
            badgeProficiency
            badgeHashtags
            waveCompletionName
            SkillChannel{
              Channel{
                id
                name
                carouselName
              }
            }
          }
          Keywords {
            id
            aka
            name
            description
            status
            updatedAt
          }
        }
        childMasterHierarchy {
          id
          archetype
          parentId
          message
          defaultSelect
          mandateData
          status
          addToMyPlan
          updatedAt
          createdAt
          KeywordsGroup {
            id
            Keywords {
              id
              aka
              name
              description
              status
              updatedAt
            }
          }
          JobProfileRoleMapping {
            JobArchitecture {
              jobprofileHR
              id
            }
          }
        }
      }
    }
  }
}`;

export const getCurriculumQuery = `query {
  channel{
    id
    name
    carouselName
  }
}`;

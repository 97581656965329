import { getPoliciesSkillQuery, updateSkillPoliciesQuery } from '../queries';

// eslint-disable-next-line operator-linebreak
const ENVURL = window.location.origin.includes('admin.genome.genpact.com') ? 'https://api.genpact.com/prod/genome/my-profile/graphql' : 'https://genome-dev.genpact.com/api/my-profile/graphql';

const checkAccess = (context, page, actionName) => {
  const accessJson = JSON.parse(localStorage.getItem('rbac_user'));
  const findObj = accessJson.pages.find((obj) => obj.code === page) || {};
  const userHasAccess = 'permission' in findObj && findObj.permission.some((objx) => objx === actionName);
  if (!userHasAccess) {
    context.dispatch('addToastNotifications', {
      text: "You don't have permission to perform this action!",
      type: 'error',
      timer: 3000,
    });
    return true;
  }
  return false;
};

export default ({ $http }) => ({
  // get all skillPolicies
  async getPoliciesSkill(context, variables = {}) {
    if (checkAccess(context, 6, 'read')) return true;
    const instance = $http.create({
      baseURL: ENVURL,
    });
    const { data } = await instance.post('', { query: getPoliciesSkillQuery, variables });
    context.commit('setPoliciesSkillInStore', data?.data?.SkillPolicy || []);
    return data?.data?.SkillPolicy;
  },
  // update skill policy
  async updatePolicySkill(context, variables) {
    if (checkAccess(context, 6, 'update')) return true;
    const instance = $http.create({
      baseURL: ENVURL,
    });
    const { data } = await instance.post('', { query: updateSkillPoliciesQuery, variables });
    return data?.data?.SkillPoliciesUpdate;
  },
  // Refresh skill policy
  async refreshPolicySkill(context, uuid) {
    if (checkAccess(context, 6, 'update')) return true;
    const url = window.location.origin.includes('admin.genome.genpact.com')
      ? `https://api.genpact.com/prod/genome/data-hose/updateIdealProfileByMasterHierarchy/${uuid}`
      : `https://api.genpact.com/uat/genome/data-hose/updateIdealProfileByMasterHierarchy/${uuid}`;
    const data = await fetch(url)
      .then((response) => response.json())
      .then((resp) => JSON.stringify(resp));
    return data;
  },
});

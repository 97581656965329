// eslint-disable-next-line operator-linebreak
const baseUrl = window.location.origin.includes('admin.genome.genpact.com')
  ? 'https://api.genpact.com/prod/genome/data-hose'
  : 'https://api.genpact.com/uat/genome/data-hose';

const checkAccess = (context, page, actionName) => {
  const accessJson = JSON.parse(localStorage.getItem('rbac_user'));
  const findObj = accessJson.pages.find((obj) => obj.code === page) || {};
  const userHasAccess = 'permission' in findObj && findObj.permission.some((objx) => objx === actionName);
  // alert(JSON.stringify(userHasAccess))
  if (!userHasAccess) {
    context.dispatch('addToastNotifications', {
      text: "You don't have permission to perform this action!",
      type: 'error',
      timer: 3000,
    });
    return true;
  }
  return false;
};

export default ({ $http }) => ({
  // Get all HistoryTrail
  async getHistoryTrail(context, data) {
    if (checkAccess(context, 8.5, 'read')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/historyTrailAPI`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    await $http
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        context.commit('setHistoryTrailInStore', response?.data || {});
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
});

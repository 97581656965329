// eslint-disable-next-line operator-linebreak
const baseUrl1 = window.location.origin.includes('admin.genome.genpact.com')
  ? 'https://api.genpact.com/prod/genome/data-hose'
  : 'https://api.genpact.com/uat/genome/data-hose';
const baseUrl = window.location.origin.includes('admin.genome.genpact.com') ? 'https://genome.genpact.com/api' : 'https://genome-dev.genpact.com/api';

const checkAccess = (context, page, actionName) => {
  const accessJson = JSON.parse(localStorage.getItem('rbac_user'));
  const findObj = accessJson.pages.find((obj) => obj.code === page) || {};
  const userHasAccess = 'permission' in findObj && findObj.permission.some((objx) => objx === actionName);
  // alert(JSON.stringify(userHasAccess))
  if (!userHasAccess) {
    context.dispatch('addToastNotifications', {
      text: "You don't have permission to perform this action!",
      type: 'error',
      timer: 3000,
    });
    return true;
  }
  return false;
};

export default ({ $http }) => ({
  // Get Academy
  async getAcademy(context, data) {
    if (checkAccess(context, 15, 'read')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/academy/getAcademyByNameStatusType`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    await $http
      .request(config)
      .then((response) => {
      // console.log(JSON.stringify(response.data));
        context.commit('setAcademyListInStore', response?.data?.body || {});
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async getAllAcademies(context, data) {
    if (checkAccess(context, 15, 'read')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/academy/getAcademyByNameStatusType`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    let academies = await $http
      .request(config)
      .then((response) => {
        console.log(response?.data?.body?.result);
        return response?.data?.body?.result;
      })
      .catch((error) => {
        console.log(error);
      });

      return academies;
  },
  async getAcademyList1(context, data) {
    if (checkAccess(context, 15, 'read')) return true;
    const config = {
      method: 'get',
      url: `${baseUrl}/academy/learners/enrollment/requests/${data.academyId}`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
    };
    await $http
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        context.commit('setAcademyListInStore1', response?.data || {});
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async enrollmentStatus(context, data) {
    if (checkAccess(context, 15, 'read')) return true;
    const config = {
      method: 'get',
      url: `${baseUrl}/academy/learners/enrollment/status/${data.academyId}`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
    };
    await $http
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        context.commit('setEnrollmentStatusListInStore1', response?.data || {});
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async updateApprove(context, data) {
    if (checkAccess(context, 15, 'update')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/academy/learners/enrollment/approve`,
      headers: {
        'content-type': 'application/json',
      },
      data,
    };
    const approveData = await $http
      .request(config)
      .then((response) => {
        return response?.data?.message;
      })
      .catch((error) => {
        console.log(error);
      });
    return approveData;
  },

  async createAcademyFunc(context, data) {
    if (checkAccess(context, 15, 'update')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/academy/createAcademy`,
      headers: {
        'content-type': 'application/json',
      },
      data,
    };
    const academyData = await $http
      .request(config)
      .then((response) => {
        // console.log(response?.data)
        return response?.data?.message;
      })
      .catch((error) => {
        console.log(error);
        this.$store.dispatch('addToastNotifications', {
          text: 'Something went wrong!',
          type: 'error',
          timer: 3000,
        });
      });
    return academyData;
  },

  async updateAcademyFunc(context, data) {
    // console.log("IN here update")
    if (checkAccess(context, 15, 'update')) return true;
    // console.log("in here after checkAccess")
    let id = data.academyId;
    // console.log("id : ", id);
    const config = {
      method: 'put',
      url: `${baseUrl}/academy/updateAcademy/${id}`,
      headers: {
        'content-type': 'application/json',
      },
      data,
    };
    const updatedAcademyData = await $http
      .request(config)
      .then((response) => {
        // console.log("response :", response.data);
        // console.log(response?.data)
        return response?.data?.body;
      })
      .catch((error) => {
        console.log(error);
        this.$store.dispatch('addToastNotifications', {
          text: 'Something went wrong!',
          type: 'error',
          timer: 3000,
        });
      });
    return updatedAcademyData;
  },

  async checkUniqueAcademy(context, data) {
    if (checkAccess(context, 15, 'read')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/academy/uniqueAcademy`,
      headers: {
        'content-type': 'application/json',
      },
      data,
    };
    const uniqueCheck = await $http
      .request(config)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
        this.$store.dispatch('addToastNotifications', {
          text: 'Something went wrong!',
          type: 'error',
          timer: 3000,
        });
      });
    return uniqueCheck;
  },

  async getModules(context, data) {
    if (checkAccess(context, 15, 'read')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/academy/modulesByAcademyId`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    await $http
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        context.commit('setModuleListInStore', response?.data || {});
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async getSkillList(context, data) {
    if (checkAccess(context, 15, 'read')) return true;
    const config = {
      method: 'get',
      url: `${baseUrl}/academy/getAllSkillsList`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      }
    };
    let skills = await $http
      .request(config)
      .then((response) => {
        // console.log("response :: ", response?.data?.skillList?.skillList);
        return response?.data?.skillList?.skillList;
      })
      .catch((error) => {
        console.log(error);
      });

    return skills;
  },

  async getSubSkillList(context, data) {
    if (checkAccess(context, 15, 'read')) return true;
    // console.log("data : ", data);
    const config = {
      method: 'get',
      url: `${baseUrl}/academy/learners/subskillsBySkillMasterId?skillMasterId=${data.skillmasterId}`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
    };
    let subskills = await $http
      .request(config)
      .then((response) => {
        // console.log("response :: ", response?.data?.subskills);
        return response?.data.subskills;
      })
      .catch((error) => {
        console.log(error);
      });

    return subskills;
  },

  async downloadFile(context, data) {
    if (checkAccess(context, 15, 'update')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/academy/learners/exportCsv`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    const response = await $http
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        if (response && response.data) {
          if (response.data.url) {
            window.open(response.data.url, '_blank');
          }
        }
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return response;
  },

  async getAllLearnersListByAcademyId(context, data) {
    if (checkAccess(context, 15, 'read')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/academy/getLearnerByAccademyId/${data.id}`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    const response = await $http
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        context.commit('setLearnerListInStore', response?.data?.body || {});
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return response;
  },

  async createModule(context, data) {
    // console.log("IN here actions")
    if (checkAccess(context, 15, 'update')) return true;
    // console.log("in here after checkAccess")
    const config = {
      method: 'post',
      url: `${baseUrl}/academy/createAcademyModule`,
      headers: {
        'content-type': 'application/json',
      },
      data,
    };
    const moduleData = await $http
      .request(config)
      .then((response) => {
        // console.log(response?.data)
        return response?.data?.message;
      })
      .catch((error) => {
        console.log(error);
        this.$store.dispatch('addToastNotifications', {
          text: 'Something went wrong!',
          type: 'error',
          timer: 3000,
        });
      });
    return moduleData;
  },

  async checkUniqueModule(context, data) {
    if (checkAccess(context, 15, 'read')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/academy/module/uniquePerAcademy`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data
    };
    let res = await $http
      .request(config)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return res;
  },

  async deleteModule(context, data) {
    // console.log("IN here actions")
    if (checkAccess(context, 15, 'update')) return true;
    // console.log("in here after checkAccess")
    const config = {
      method: 'delete',
      url: `${baseUrl}/academy/deleteModule/${data.moduleId}/${data.moduleName}`,
      headers: {
        'content-type': 'application/json',
      }
    };
    const moduleData = await $http
      .request(config)
      .then((response) => {
        // console.log(response?.data?.body)
        return response?.data?.body;
      })
      .catch((error) => {
        console.log(error);
        this.$store.dispatch('addToastNotifications', {
          text: 'Something went wrong!',
          type: 'error',
          timer: 3000,
        });
      });
    return moduleData;
  },

  async updateModule(context, data) {
    // console.log("IN here actions")
    // console.log("data : ", data);
    if (checkAccess(context, 15, 'update')) return true;
    // console.log("in here after checkAccess")
    const config = {
      method: 'put',
      url: `${baseUrl}/academy/updateModule/${data.moduleId}`,
      headers: {
        'content-type': 'application/json',
      },
      data
    };
    const moduleData = await $http
      .request(config)
      .then((response) => {
        // console.log(response?.data?.body)
        return response?.data?.body;
      })
      .catch((error) => {
        console.log(error);
        this.$store.dispatch('addToastNotifications', {
          text: 'Something went wrong!',
          type: 'error',
          timer: 3000,
        });
      });
    return moduleData;
  },

  async getCategoryLists(context, data) {
    if (checkAccess(context, 15, 'read')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl1}/getMdmSkills`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    await $http
      .request(config)
      .then((response) => {
        // console.log("response?.data", response?.data);
        context.commit('setCategoryListInStore', response?.data || {});
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async downloadAcademies(context, data) {
    if (checkAccess(context, 15, 'update')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/academy/downloadAcademies`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    const response = await $http
      .request(config)
      .then((response) => {
        console.log(response);
        if (response && response.data) {
          if (response.data.url) {
            window.open(response.data.url, '_blank');
          }
        }
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return response;
  },


  async getAllFunctionAndRoleByAcademyId(context, data) {
    if (checkAccess(context, 15, 'update')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/academy/FunctionRolesByAcademyId`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    const response = await $http
    .request(config)
    .then((response) => {
      context.commit('setFunctionAndRoleListInStore', response?.data || {});
      return response?.data;
    })
    .catch((error) => {
      console.log(error);
    });
    return response;
  },

  async deleteFunctionRoleMappingInActions(context, data){
    if (checkAccess(context, 15, 'read')) return true;
    const config = {
      method: 'get',
      url: `${baseUrl}/academy/deleteFunctionRoleMapping?id=${data}`,
    };
    const response = await $http
    .request(config)
    .then((response) => {
      if (response && response.data) {
        if (response.data.message === 'Successfully deleted') {
          window.location.reload();
        }
      }
      return response?.data;
    })
    .catch((error) => {
      console.log(error);
    });
    return response;
  },

  async pushFunctionAndRolewithAcademyId(context, data){
    if (checkAccess(context, 15, 'update')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/academy/savefunctionRoleMapping`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    const response = await $http
    .request(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
    return response;
  },
  
  async deleteSelectedLearnersActions(context, data) {
    // console.log("IN here actions")
    if (checkAccess(context, 15, 'update')) return true;
    // console.log("in here after checkAccess")
    const config = {
      method: 'post',
      url: `${baseUrl}/academy/learners/enrollment/delete`,
      headers: {
        'content-type': 'application/json',
      },
      data,
    };
    const response = await $http
      .request(config)
      .then((response) => {
        // console.log(response?.data?.body)
        return response;
      })
      .catch((error) => {
        console.log(error);
        this.$store.dispatch('addToastNotifications', {
          text: 'Something went wrong!',
          type: 'error',
          timer: 3000,
        });
      });
    return response;
  },


});

import mgCertificateActionModule from './criticalSkill-actions';

const initializeState = () => ({
  criticalSkillList: [],
  skillMappingList: {},
  academyList: [],
  segmentList:[],
  groupList: [],
  unitList: [],
});

export default ({ $http }) => ({
  state: {
    ...initializeState(),
  },
  mutations: {
    setCriticalSkillInStore(state, payload) {
      state.criticalSkillList = payload|| [];
    },
    setCriticalSkillMappingInStore(state, payload) {
      state.skillMappingList = payload || {};
    },
    setAcademyInStore(state, payload){
      state.academyList = payload || []
    },
    setSegmentlInStore(state, payload){
      state.segmentList = payload || []
    },
    setGroupInStore(state, payload){
      state.groupList = payload || []
    },
    setUnitInStore(state, payload){
      state.unitList = payload || []
    },
  },
  actions: mgCertificateActionModule({ $http }),
  getters: {
    criticalSkill: (state) => state.criticalSkillList || [],
    skillMapping: (state) => state.skillMappingList || {},
    academyList: (state) => state.academyList  || [],
    segmentList: (state) => state.segmentList || [],
    groupList: (state) => state.groupList || [],
    unitList: (state) => state.unitList || [],
  },
});

import eventActionModule from './event-actions';

const initializeState = () => ({
  eventList: [],
  eventListCount: 0,
  eventData: [],
  countByStatus: [],
  eventDataCount: 0,
});

export default ({ $http }) => ({
  state: {
    ...initializeState(),
  },
  mutations: {
    setEventListInStore(state, payload) {
      // console.log(payload);
      state.eventListCount = payload?.count || [];
      state.eventList = payload?.eventList || [];
    },
    setEventDataInStore(state, payload) {
      // console.log(payload);
      state.eventDataCount = payload?.count || 0;
      state.countByStatus = payload?.countByStatus || [];
      state.eventData = payload?.eventList || [];
    },
  },
  actions: eventActionModule({ $http }),
  getters: {
    eventList: (state) => state.eventList,
    eventListCount: (state) => state.eventListCount,
    eventData: (state) => state.eventData,
    countByStatus: (state) => state.countByStatus,
    eventDataCount: (state) => state.eventDataCount,
  },
});

import skillCatogoryActionModule from './skillCategory-action';

const initializeState = () => ({
  
  skillcategory: {  },
});

export default ({ $http }) => ({
  state: {
    ...initializeState(),
  },
  mutations: {
    setSkillCategoryInStore(state, payload) {
      state.skillcategory = payload || { };
      if(state.skillcategory){
        payload.masters.map(s => (s.key = 'skillCategory', s));
        payload.primarySkills.map(s => (s.key = 'primarySkill', s));
        payload.subSkills.map(s => (s.key = 'subSkill', s));
      }
    },
  },
  actions: skillCatogoryActionModule({ $http }),
  getters: {
    skillCategory: (state) => state.skillcategory,
  },
});

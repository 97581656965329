// eslint-disable-next-line operator-linebreak
  const baseUrl = window.location.origin.includes('admin.genome.genpact.com') ? 'https://api.genpact.com/prod/genome' : 'https://api.genpact.com/uat/genome';

const checkAccess = (context, page, actionName) => {
  const accessJson = JSON.parse(localStorage.getItem('rbac_user'));
  const findObj = accessJson.pages.find((obj) => obj.code === page) || {};
  const userHasAccess = 'permission' in findObj && findObj.permission.some((objx) => objx === actionName);
  // alert(JSON.stringify(userHasAccess))
  if (!userHasAccess) {
    context.dispatch('addToastNotifications', {
      text: "You don't have permission to perform this action!",
      type: 'error',
      timer: 3000,
    });
    return true;
  }
  return false;
};

export default ({ $http }) => ({
  // Get Critical Skill List
  async getCriticalSkillList(context, data) {
    console.log(JSON.stringify('data', data));
    if (checkAccess(context, 8.6, 'read')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/my-profile/criticalskill/list`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    await $http
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        context.commit('setCriticalSkillInStore', response?.data || {});
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async criticalSkillMappingList(context, data) {
    if (checkAccess(context, 8.6, 'read')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/my-profile/criticalskill/mapping/list`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    await $http
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        context.commit('setCriticalSkillMappingInStore', response?.data || {});
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  // Get Academy
  async getAcademyList(context, data) {
    if (checkAccess(context, 8.6, 'read')) return true;
    const config = {
      method: 'get',
      url: `${baseUrl}/academy/getAllAcccademies?academy_type=active`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    await $http
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        context.commit('setAcademyInStore', response?.data?.body || {});
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  // Get Segment
  async getSegment(context, data) {
    if (checkAccess(context, 8.6, 'read')) return true;
    const config = {
      method: 'get',
      url: `${baseUrl}/my-profile/offcon/list/vertHighGroup`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    await $http
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        context.commit('setSegmentlInStore', response?.data || {});
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  // Get Group
  async getGroup(context, data) {
    if (checkAccess(context, 8.6, 'read')) return true;
    const config = {
      method: 'get',
      url: `${baseUrl}/my-profile/offcon/list/group`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    await $http
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        context.commit('setGroupInStore', response?.data || {});
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
// Get Unit
async getUnit(context, data) {
  if (checkAccess(context, 8.6, 'read')) return true;
  const config = {
    method: 'get',
    url: `${baseUrl}/my-profile/offcon/list/unit`,
    headers: {
      'content-type': 'application/json;charset=UTF-8',
    },
    data,
  };
  await $http
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data));
      context.commit('setUnitInStore', response?.data || {});
      return response?.data;
    })
    .catch((error) => {
      console.log(error);
    });
},
  // Add/Update Critical Skill
  async updateCriticalSkill(context, data) {
    if (checkAccess(context, 8.6, 'update')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/my-profile/criticalskill/update`,
      headers: {
        'content-type': 'application/json',
      },
      data,
    };
    const waveData = await $http
      .request(config)
      .then((response) => {
        return response?.data?.message;
      })
      .catch((error) => {
        console.log(error);
      });
    return waveData;
  },
  // Add/Update Critical skill Mapping
  async updateCriticalSkillMapping(context, data) {
    if (checkAccess(context, 8.6, 'update')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/my-profile/criticalskill/mapping/update`,
      headers: {
        'content-type': 'application/json',
      },
      data,
    };
    const waveData = await $http
      .request(config)
      .then((response) => {
        return response?.data?.message;
      })
      .catch((error) => {
        console.log(error);
      });
    return waveData;
  },
  // Activate/Diactivate Status
  async activateCriticalSkillStatus(context, data) {
    if (checkAccess(context, 8.6, 'update')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/my-profile/criticalskill/updatestatus`,
      headers: {
        'content-type': 'application/json',
      },
      data,
    };
    const skillStatus = await $http
      .request(config)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return skillStatus;
  },
});

import jobArchitectureActionModule from './jobArchitecture-actions';

const initializeState = () => ({
  jobFamilyGroup: {},
  jobFamily: {},
  jobTitle: {},
  jobProfile: {},
  roleList: [],
  jobProfileList: [],
  coreSkillList: [],
  categoryList: [],
  functionList: []
});

export default ({ $http }) => ({
  state: {
    ...initializeState(),
  },
  mutations: {
    setJobGroupInStore(state, payload) {
      state.jobFamilyGroup = payload|| {};
    },
    setJobFamilyInStore(state, payload){
      state.jobFamily = payload|| {};
    },
    setJobTitleInStore(state, payload){
      state.jobTitle = payload|| {};
    },
    setJobProfileInStore(state, payload){
      state.jobProfile = payload|| {};
    },
    setRoleListInStore(state, payload){
      state.roleList = payload|| {};
    },
    setJobProfileListInStore(state, payload){
      state.jobProfileList = payload|| {};
    },
    setCoreSkillListInStore(state, payload){
      state.coreSkillList = payload || [];
    },
    setCategoryListInStore(state, payload){
      state.categoryList = payload || [];
    },
    setFunctionListInStore(state, payload){
      state.functionList = payload || [];
    },
  },
  actions: jobArchitectureActionModule({ $http }),
  getters: {
    JobFamilyGroup: (state) => state.jobFamilyGroup || {},
    JobFamily: (state) => state.jobFamily || {},
    JobTitle: (state) => state.jobTitle || {},
    JobProfile: (state) => state.jobProfile || {},
    RoleList: (state) => state.roleList || [],
    JobProfileList: (state) => state.jobProfileList || [],
    coreSkillList: (state) => state.coreSkillList || [],
    categoryList: (state ) => state.categoryList || [],
    functionList: (state ) => state.functionList || []
  },
});

import skillsActionModule from './skills-actions';

const initializeState = () => ({
  skillsByMaster: [],
  skillLevels: [],
  skillCurriculum: [],
});

export default ({ $http }) => ({
  state: {
    ...initializeState(),
  },
  mutations: {
    setSkillsByMastersInStore(state, payload) {
      // console.log(payload);
      state.skillLevels = payload?.Levels || [];
      // eslint-disable-next-line indent, operator-linebreak
      state.skillsByMaster =
        [...payload?.Master]?.sort((a, b) => {
          if (a.code < b.code) return -1;
          if (a.code > b.code) return 1;
          return 0;
        }) || [];
      // if (state.masterIdArr.length) {
      //   const bpp = Object.keys(state.skillsByMaster).some((obj) => obj === payload.master);
      //   const sbp = state.masterIdArr.some((obj) => obj.id === payload.id);
      //   if (!bpp) state.skillsByMaster = { ...state.skillsByMaster, [payload.master]: payload.data.body || [] };
      //   if (!sbp) state.masterIdArr.push({ id: payload.id, master: payload.master });
      //   else {
      //     state.masterIdArr.map((obj) => {
      //       const abc = obj;
      //       if (abc.id === payload.id) {
      //         abc.master = payload.master;
      //         return abc;
      //       }
      //       return abc;
      //     });
      //   }
      // } else {
      //   state.skillsByMaster = { ...state.skillsByMaster, [payload.master]: payload.data.body || [] };
      //   state.masterIdArr.push({ id: payload.id, master: payload.master });
      // }
    },
    setCurriculumInStore(state, payload){
      state.skillCurriculum = payload;
    }
  },
  actions: skillsActionModule({ $http }),
  getters: {
    skillLevels: (state) => state.skillLevels,
    skillsByMaster: (state) => state.skillsByMaster,
    skillCurriculum: (state) => state.skillCurriculum,
  },
});

import skillCatogoryActionModule from './skillCapability-action';

const initializeState = () => ({
  
  skillcapability: {  },
});

export default ({ $http }) => ({
  state: {
    ...initializeState(),
  },
  mutations: {
    setSkillCapabilityInStore(state, payload) {
      state.skillcapability = payload || { };
    },
  },
  actions: skillCatogoryActionModule({ $http }),
  getters: {
    skillCapability: (state) => state.skillcapability,
  },
});

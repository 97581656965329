import mgCertificateActionModule from './mgCertificate-actions';

const initializeState = () => ({
  wavelist: [],
});

export default ({ $http }) => ({
  state: {
    ...initializeState(),
  },
  mutations: {
    setMGCertificateInStore(state, payload) {
      state.wavelist = payload|| [];
    },
  },
  actions: mgCertificateActionModule({ $http }),
  getters: {
    mgwaveList: (state) => state.wavelist || [],
  },
});
